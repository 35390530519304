import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
};

export type ApplyResourcePricingInput = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  resourceName: Scalars['String']['input'];
};

export type ApproveQuotaInput = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  resourceId: Scalars['Int']['input'];
  reviewNotes?: InputMaybe<Scalars['String']['input']>;
  reviewedBy?: InputMaybe<Scalars['Int']['input']>;
};

export type AssignFloatingIpInput = {
  /** projectUuid */
  projectUuid: Scalars['String']['input'];
  /** vmNicReference */
  vmNicReference: Scalars['String']['input'];
};

export type ChartData = {
  __typename?: 'ChartData';
  disk: Scalars['Int']['output'];
  memory: Scalars['Int']['output'];
  publicIps?: Maybe<Scalars['Int']['output']>;
  vcpu: Scalars['Int']['output'];
};

export type CheckEmailConfirmationInput = {
  token: Scalars['String']['input'];
};

export type CheckUserEmailInput = {
  email: Scalars['String']['input'];
};

export type CheckUserUsernameInput = {
  username: Scalars['String']['input'];
};

export type ClearFloatingIpInput = {
  /** extId */
  extId: Scalars['String']['input'];
};

export type Companies = {
  __typename?: 'Companies';
  count?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['Int']['output']>;
  nodes?: Maybe<Array<Company>>;
};

export type Company = {
  __typename?: 'Company';
  approvedQuotas?: Maybe<Array<Quota>>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  pendingQuotas?: Maybe<Array<Quota>>;
  resourcePricings?: Maybe<Array<ResourcePricingOnCompany>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyChartData = {
  __typename?: 'CompanyChartData';
  allocated: ChartData;
  projects: Array<LocalProject>;
  usage: ChartData;
  userCount: Scalars['Int']['output'];
};

export type ConfirmEmailInput = {
  token: Scalars['String']['input'];
};

export type CreateCompanyInput = {
  disk?: InputMaybe<Scalars['Float']['input']>;
  internet?: InputMaybe<Scalars['Float']['input']>;
  memory?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  publicIps?: InputMaybe<Scalars['Float']['input']>;
  vcpu?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateLocalProjectInput = {
  companyId: Scalars['Int']['input'];
  disk: Scalars['Int']['input'];
  gatewayIp: Scalars['String']['input'];
  memory: Scalars['Int']['input'];
  networkIp: Scalars['String']['input'];
  policyUuid: Scalars['String']['input'];
  postfixIndex: Scalars['Int']['input'];
  projectName: Scalars['String']['input'];
  projectUuid: Scalars['String']['input'];
  quotaUuid: Scalars['String']['input'];
  routeTableUuid: Scalars['String']['input'];
  subnetName: Scalars['String']['input'];
  subnetUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
  username: Scalars['String']['input'];
  vcpu: Scalars['Int']['input'];
  vpcName: Scalars['String']['input'];
  vpcUuid: Scalars['String']['input'];
};

export type CreateMemberInput = {
  companyId: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNutanixProjectInput = {
  /** Company id */
  companyId: Scalars['Int']['input'];
  /** Disk size in GB */
  disk: Scalars['Int']['input'];
  /** DNS Server IP Address */
  dnsServerIp: Scalars['String']['input'];
  /** End Address */
  endIp: Scalars['String']['input'];
  /** Gateway IP Address */
  gatewayIp: Scalars['String']['input'];
  /** Memory in GB */
  memory: Scalars['Int']['input'];
  /** Network IP Address / Prefix */
  networkIp: Scalars['String']['input'];
  /** Postfix index */
  postfixIndex: Scalars['Int']['input'];
  /** Start Address */
  startIp: Scalars['String']['input'];
  /** Number of virtual CPUs (vCPU) */
  vcpu: Scalars['Int']['input'];
};

export type CreatePermissionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  scopeId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type CreateQuotaInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  quota: Scalars['Float']['input'];
  resourceId: Scalars['Int']['input'];
};

export type CreateResourceInput = {
  max: Scalars['Int']['input'];
  min: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  type?: InputMaybe<GuardType>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type CreateResourcePricingInput = {
  memo?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  resourceId: Scalars['Int']['input'];
};

export type CreateRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  permissionIds: Array<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  type: GuardType;
};

export type CreateScopeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: GuardType;
};

export type CreateUserInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  roleIds: Array<Scalars['Int']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

/** Currency for pricings */
export enum Currency {
  Eur = 'EUR',
  Gbp = 'GBP',
  Jpy = 'JPY',
  Kgs = 'KGS',
  Kzt = 'KZT',
  Rub = 'RUB',
  Sum = 'SUM',
  Tjs = 'TJS',
  Usd = 'USD',
}

export type DailyCost = {
  __typename?: 'DailyCost';
  date: Scalars['DateTime']['output'];
  totalCost: Scalars['Float']['output'];
};

export type DailyResourceCost = {
  __typename?: 'DailyResourceCost';
  day: Scalars['DateTime']['output'];
  resourceCosts: Array<ResourceCost>;
  totalCost: Scalars['Float']['output'];
};

export type DeletePermissionInput = {
  permissionId: Scalars['Int']['input'];
};

export type DeleteRoleInput = {
  roleId: Scalars['Int']['input'];
};

export type DeleteScopeInput = {
  scopeId: Scalars['Int']['input'];
};

export type DeleteUserInput = {
  id: Scalars['Int']['input'];
};

export type EnableOtpInput = {
  token: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type FloatingIp = {
  __typename?: 'FloatingIp';
  ipv4: IPv4;
};

export type FloatingIpEntity = {
  __typename?: 'FloatingIpEntity';
  extId: Scalars['String']['output'];
  floatingIp: FloatingIp;
};

export type ForgotPasswordInput = {
  email: Scalars['String']['input'];
};

export type GenerateOtpInput = {
  userId: Scalars['Int']['input'];
};

export type GenerateOtpResponse = {
  __typename?: 'GenerateOtpResponse';
  otpAuthUrl: Scalars['String']['output'];
  otpSecret: Scalars['String']['output'];
};

export type GetCompaniesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GetCompanyChartData = {
  companyId: Scalars['Int']['input'];
};

export type GetCompanyInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GetDailyCostForOrganizationInput = {
  companyId: Scalars['Int']['input'];
  month: Scalars['DateTime']['input'];
};

export type GetDailyResourceCostForOrganizationInput = {
  companyId: Scalars['Int']['input'];
  month: Scalars['DateTime']['input'];
};

export type GetInvoicesInput = {
  companyId?: InputMaybe<Scalars['Float']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  status?: InputMaybe<InvoiceStatus>;
};

export type GetLocalProjectInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
  projectUuid?: InputMaybe<Scalars['String']['input']>;
};

export type GetLocalProjectsInput = {
  companyId?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  projectName?: InputMaybe<Scalars['String']['input']>;
  projectUuid?: InputMaybe<Scalars['String']['input']>;
  subnetUuid?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  vpcUuid?: InputMaybe<Scalars['String']['input']>;
};

export type GetMembersInput = {
  companyId: Scalars['Int']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  permissionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  roleIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type GetMonthlyCostForOrganizationInput = {
  companyId: Scalars['Int']['input'];
  endMonth: Scalars['DateTime']['input'];
  startMonth: Scalars['DateTime']['input'];
};

export type GetPermissionInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GetPermissionsInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  roleIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  scopeId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GetProjectsChartData = {
  companyId: Scalars['Int']['input'];
  projectUuid: Scalars['String']['input'];
};

export type GetQuotasInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  resourceId?: InputMaybe<Scalars['Int']['input']>;
  reviewNotes?: InputMaybe<Scalars['String']['input']>;
  reviewedBy?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<QuotaStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GetResourceInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type GetResourcePricingInput = {
  id?: InputMaybe<Scalars['Float']['input']>;
};

export type GetResourcePricingsInput = {
  companyId?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  resourceId?: InputMaybe<Scalars['Float']['input']>;
};

export type GetResourcesInput = {
  guardType?: InputMaybe<GuardType>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<PaginationInput>;
};

export type GetRoleInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GetRolesInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  permissionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GetScopeInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GetScopesInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  permissionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GetUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type GetUsersInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  permissionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  roleIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type GetVmsInput = {
  companyId: Scalars['Int']['input'];
};

export type GetVmsResponse = {
  __typename?: 'GetVmsResponse';
  /** Allocated floating IPs */
  allocated: Scalars['Int']['output'];
  /** VM entities */
  entities: Array<VmEntity>;
  /** Usage floating IPs */
  usage: Scalars['Int']['output'];
};

export enum GuardType {
  Admin = 'ADMIN',
  System = 'SYSTEM',
  User = 'USER',
}

export type IPv4 = {
  __typename?: 'IPv4';
  value: Scalars['String']['output'];
};

export type Invoice = {
  __typename?: 'Invoice';
  cancelDate?: Maybe<Scalars['DateTime']['output']>;
  company?: Maybe<Company>;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  dueDate: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  invoiceNumber: Scalars['String']['output'];
  paidDate?: Maybe<Scalars['DateTime']['output']>;
  status: Scalars['String']['output'];
  totalAmount: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Invoice status */
export enum InvoiceStatus {
  Canceled = 'CANCELED',
  Hold = 'HOLD',
  Paid = 'PAID',
  Pending = 'PENDING',
}

export type Invoices = {
  __typename?: 'Invoices';
  count?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['Int']['output']>;
  nodes?: Maybe<Array<Invoice>>;
};

export type LocalProject = {
  __typename?: 'LocalProject';
  createdAt: Scalars['DateTime']['output'];
  gatewayIp: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  networkIp: Scalars['String']['output'];
  policyUuid: Scalars['String']['output'];
  postfixIndex: Scalars['Int']['output'];
  projectName: Scalars['String']['output'];
  projectUuid: Scalars['String']['output'];
  removingMark: Scalars['Boolean']['output'];
  resourceQuotas?: Maybe<Array<Quota>>;
  routeTableUuid: Scalars['String']['output'];
  subnetName: Scalars['String']['output'];
  subnetUuid: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userUuid: Scalars['String']['output'];
  username: Scalars['String']['output'];
  vpcName: Scalars['String']['output'];
  vpcUuid: Scalars['String']['output'];
};

export type LocalProjects = {
  __typename?: 'LocalProjects';
  count?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['Int']['output']>;
  nodes?: Maybe<Array<LocalProject>>;
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  type: LoginType;
  user: UserInfo;
};

/** Different stages of the login process */
export enum LoginType {
  ConfirmEmail = 'ConfirmEmail',
  GenerateOtp = 'GenerateOtp',
  SetPassword = 'SetPassword',
  VerifyOtp = 'VerifyOtp',
}

export type LogoutInput = {
  userId: Scalars['Int']['input'];
};

export type MarkLocalProjectInput = {
  projectUuid: Scalars['String']['input'];
  removingMark?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MonthlyCost = {
  __typename?: 'MonthlyCost';
  month: Scalars['DateTime']['output'];
  totalCost: Scalars['Float']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  applyResourcePricing: ResourcePricing;
  approveQuota: Quota;
  assignFloatingIp: Scalars['Boolean']['output'];
  checkEmailConfirmation: Scalars['Boolean']['output'];
  clearFloatingIp: Scalars['Boolean']['output'];
  confirmEmail: Scalars['Boolean']['output'];
  createCompany: Company;
  createLocalProject: LocalProject;
  createMember: UserInfo;
  createPermission: Permission;
  createProject: Scalars['Boolean']['output'];
  createQuota: Quota;
  createResource: Resource;
  createResourcePricing: ResourcePricing;
  createRole: Role;
  createScope: Scope;
  createUser: User;
  deleteCompany: Company;
  deletePermission: Scalars['Boolean']['output'];
  deleteRole: Scalars['Boolean']['output'];
  deleteScope: Scalars['Boolean']['output'];
  deleteUser: Scalars['Boolean']['output'];
  enableOtp: Scalars['Boolean']['output'];
  forgotPassword: UserInfo;
  generateOtp: GenerateOtpResponse;
  login: LoginResponse;
  logout: Scalars['Boolean']['output'];
  markLocalProject: LocalProject;
  refreshTokens: RefreshTokensResponse;
  register: UserInfo;
  rejectQuota: Quota;
  removeProject: Scalars['Boolean']['output'];
  setInvoiceCanceled: Scalars['Boolean']['output'];
  setInvoicePaid: Scalars['Boolean']['output'];
  setPassword: Scalars['Boolean']['output'];
  submitQuota: Quota;
  testToken: Scalars['String']['output'];
  updateCompany: Company;
  updateLocalProject: LocalProject;
  updatePermission: Permission;
  updateProject: Scalars['Boolean']['output'];
  updateResource: Resource;
  updateResourcePricing: ResourcePricing;
  updateRole: Role;
  updateScope: Scope;
  updateSelfUser: User;
  updateUser: User;
  verifyOtp: VerifyOtpResponse;
};

export type MutationApplyResourcePricingArgs = {
  input: ApplyResourcePricingInput;
};

export type MutationApproveQuotaArgs = {
  input: ApproveQuotaInput;
};

export type MutationAssignFloatingIpArgs = {
  input: AssignFloatingIpInput;
};

export type MutationCheckEmailConfirmationArgs = {
  input: CheckEmailConfirmationInput;
};

export type MutationClearFloatingIpArgs = {
  input: ClearFloatingIpInput;
};

export type MutationConfirmEmailArgs = {
  input: ConfirmEmailInput;
};

export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};

export type MutationCreateLocalProjectArgs = {
  input: CreateLocalProjectInput;
};

export type MutationCreateMemberArgs = {
  input: CreateMemberInput;
};

export type MutationCreatePermissionArgs = {
  input: CreatePermissionInput;
};

export type MutationCreateProjectArgs = {
  input: CreateNutanixProjectInput;
};

export type MutationCreateQuotaArgs = {
  input: CreateQuotaInput;
};

export type MutationCreateResourceArgs = {
  input: CreateResourceInput;
};

export type MutationCreateResourcePricingArgs = {
  input: CreateResourcePricingInput;
};

export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};

export type MutationCreateScopeArgs = {
  input: CreateScopeInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteCompanyArgs = {
  id: Scalars['Float']['input'];
};

export type MutationDeletePermissionArgs = {
  input: DeletePermissionInput;
};

export type MutationDeleteRoleArgs = {
  input: DeleteRoleInput;
};

export type MutationDeleteScopeArgs = {
  input: DeleteScopeInput;
};

export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

export type MutationEnableOtpArgs = {
  input: EnableOtpInput;
};

export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};

export type MutationGenerateOtpArgs = {
  input: GenerateOtpInput;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationLogoutArgs = {
  input: LogoutInput;
};

export type MutationMarkLocalProjectArgs = {
  input: MarkLocalProjectInput;
};

export type MutationRefreshTokensArgs = {
  input: RefreshTokensInput;
};

export type MutationRegisterArgs = {
  input: RegisterInput;
};

export type MutationRejectQuotaArgs = {
  input: RejectQuotaInput;
};

export type MutationRemoveProjectArgs = {
  input: RemoveNutanixProjectInput;
};

export type MutationSetInvoiceCanceledArgs = {
  input: SetInvoiceCanceled;
};

export type MutationSetInvoicePaidArgs = {
  input: SetInvoicePaid;
};

export type MutationSetPasswordArgs = {
  input: SetPasswordInput;
};

export type MutationSubmitQuotaArgs = {
  input: SubmitQuotaInput;
};

export type MutationTestTokenArgs = {
  input: TestTokenInput;
};

export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};

export type MutationUpdateLocalProjectArgs = {
  input: UpdateLocalProjectInput;
};

export type MutationUpdatePermissionArgs = {
  input: UpdatePermissionInput;
};

export type MutationUpdateProjectArgs = {
  input: UpdateNutanixProjectInput;
};

export type MutationUpdateResourceArgs = {
  input: UpdateResourceInput;
};

export type MutationUpdateResourcePricingArgs = {
  input: UpdateResourcePricingInput;
};

export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};

export type MutationUpdateScopeArgs = {
  input: UpdateScopeInput;
};

export type MutationUpdateSelfUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationVerifyOtpArgs = {
  input: VerifyOtpInput;
};

export type PaginationInput = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
};

export type Permission = {
  __typename?: 'Permission';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  roles?: Maybe<Roles>;
  scope?: Maybe<Scope>;
  scopeId?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type PermissionRolesArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type Permissions = {
  __typename?: 'Permissions';
  count?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['Int']['output']>;
  nodes?: Maybe<Array<Permission>>;
};

export type ProjectsChartData = {
  __typename?: 'ProjectsChartData';
  allocated: ChartData;
  usage: ChartData;
};

export type Query = {
  __typename?: 'Query';
  checkUserEmail: Scalars['Boolean']['output'];
  checkUserUsername: Scalars['Boolean']['output'];
  getCompanies: Companies;
  getCompany: Company;
  getCompanyChartData: CompanyChartData;
  getDailyCostForOrganization: Array<DailyCost>;
  getDailyResourceCostForOrganization: Array<DailyResourceCost>;
  getInvoices: Invoices;
  getLocalProject: LocalProject;
  getLocalProjects: LocalProjects;
  getMembers: Users;
  getMonthlyCostForOrganization: Array<MonthlyCost>;
  getPermission: Permission;
  getPermissions: Permissions;
  getProjectsChartData: ProjectsChartData;
  getQuota: Quota;
  getQuotas: Quotas;
  getResource: Resource;
  getResourcePricing: ResourcePricing;
  getResourcePricings: ResourcePricings;
  getResources: Resources;
  getRole: Role;
  getRoles: Roles;
  getScope: Scope;
  getScopes: Scopes;
  getSystemChartData: SystemChartData;
  getUser: User;
  getUsers: Users;
  getVms: GetVmsResponse;
};

export type QueryCheckUserEmailArgs = {
  input: CheckUserEmailInput;
};

export type QueryCheckUserUsernameArgs = {
  input: CheckUserUsernameInput;
};

export type QueryGetCompaniesArgs = {
  input: GetCompaniesInput;
};

export type QueryGetCompanyArgs = {
  input: GetCompanyInput;
};

export type QueryGetCompanyChartDataArgs = {
  input: GetCompanyChartData;
};

export type QueryGetDailyCostForOrganizationArgs = {
  input: GetDailyCostForOrganizationInput;
};

export type QueryGetDailyResourceCostForOrganizationArgs = {
  input: GetDailyResourceCostForOrganizationInput;
};

export type QueryGetInvoicesArgs = {
  input: GetInvoicesInput;
};

export type QueryGetLocalProjectArgs = {
  input: GetLocalProjectInput;
};

export type QueryGetLocalProjectsArgs = {
  input: GetLocalProjectsInput;
};

export type QueryGetMembersArgs = {
  input: GetMembersInput;
};

export type QueryGetMonthlyCostForOrganizationArgs = {
  input: GetMonthlyCostForOrganizationInput;
};

export type QueryGetPermissionArgs = {
  input: GetPermissionInput;
};

export type QueryGetPermissionsArgs = {
  input: GetPermissionsInput;
};

export type QueryGetProjectsChartDataArgs = {
  input: GetProjectsChartData;
};

export type QueryGetQuotaArgs = {
  id: Scalars['Float']['input'];
};

export type QueryGetQuotasArgs = {
  input: GetQuotasInput;
};

export type QueryGetResourceArgs = {
  input: GetResourceInput;
};

export type QueryGetResourcePricingArgs = {
  input: GetResourcePricingInput;
};

export type QueryGetResourcePricingsArgs = {
  input: GetResourcePricingsInput;
};

export type QueryGetResourcesArgs = {
  input: GetResourcesInput;
};

export type QueryGetRoleArgs = {
  input: GetRoleInput;
};

export type QueryGetRolesArgs = {
  input: GetRolesInput;
};

export type QueryGetScopeArgs = {
  input: GetScopeInput;
};

export type QueryGetScopesArgs = {
  input: GetScopesInput;
};

export type QueryGetUserArgs = {
  input: GetUserInput;
};

export type QueryGetUsersArgs = {
  input: GetUsersInput;
};

export type QueryGetVmsArgs = {
  input: GetVmsInput;
};

export type Quota = {
  __typename?: 'Quota';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  project?: Maybe<LocalProject>;
  projectId?: Maybe<Scalars['Int']['output']>;
  quota: Scalars['Float']['output'];
  resource?: Maybe<Resource>;
  resourceId?: Maybe<Scalars['Int']['output']>;
  reviewNotes?: Maybe<Scalars['String']['output']>;
  reviewedBy?: Maybe<Scalars['Int']['output']>;
  reviewer?: Maybe<User>;
  status: QuotaStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export enum QuotaStatus {
  Approved = 'APPROVED',
  Archived = 'ARCHIVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type Quotas = {
  __typename?: 'Quotas';
  count?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['Int']['output']>;
  nodes?: Maybe<Array<Quota>>;
};

export type RefreshTokensInput = {
  refreshToken: Scalars['String']['input'];
};

export type RefreshTokensResponse = {
  __typename?: 'RefreshTokensResponse';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type RegisterInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  disk?: InputMaybe<Scalars['Float']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  internet?: InputMaybe<Scalars['Float']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  memory?: InputMaybe<Scalars['Float']['input']>;
  publicIps?: InputMaybe<Scalars['Float']['input']>;
  roleIds: Array<Scalars['Int']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  vcpu?: InputMaybe<Scalars['Float']['input']>;
};

export type RejectQuotaInput = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  reviewNotes?: InputMaybe<Scalars['String']['input']>;
  reviewedBy?: InputMaybe<Scalars['Int']['input']>;
};

export type RemoveNutanixProjectInput = {
  /** Project uuid */
  uuid: Scalars['String']['input'];
};

export type Resource = {
  __typename?: 'Resource';
  createdAt: Scalars['DateTime']['output'];
  guardType?: Maybe<GuardType>;
  id: Scalars['Int']['output'];
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  resourcePricing?: Maybe<Array<ResourcePricing>>;
  resourceQuota?: Maybe<Array<Quota>>;
  unit: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ResourceCost = {
  __typename?: 'ResourceCost';
  cost: Scalars['Float']['output'];
  pricePerUnit: Scalars['Float']['output'];
  quota: Scalars['Int']['output'];
  resourceName: Scalars['String']['output'];
  unit: Scalars['String']['output'];
};

export type ResourcePricing = {
  __typename?: 'ResourcePricing';
  companies?: Maybe<Array<ResourcePricingOnCompany>>;
  createdAt: Scalars['DateTime']['output'];
  currency?: Maybe<Currency>;
  id: Scalars['Int']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  resource?: Maybe<Resource>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ResourcePricingOnCompany = {
  __typename?: 'ResourcePricingOnCompany';
  company: Company;
  companyId: Scalars['Int']['output'];
  resourcePricing: ResourcePricing;
  resourcePricingId: Scalars['Int']['output'];
};

export type ResourcePricings = {
  __typename?: 'ResourcePricings';
  count?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['Int']['output']>;
  nodes?: Maybe<Array<ResourcePricing>>;
};

export type Resources = {
  __typename?: 'Resources';
  count?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['Int']['output']>;
  nodes?: Maybe<Array<Resource>>;
};

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  permissions?: Maybe<Permissions>;
  title: Scalars['String']['output'];
  type?: Maybe<GuardType>;
  users?: Maybe<Array<UserRoles>>;
};

export type RolePermissionsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type Roles = {
  __typename?: 'Roles';
  count?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['Int']['output']>;
  nodes?: Maybe<Array<Role>>;
};

export type Scope = {
  __typename?: 'Scope';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  permissions?: Maybe<Permissions>;
  title: Scalars['String']['output'];
  type?: Maybe<GuardType>;
};

export type ScopePermissionsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type Scopes = {
  __typename?: 'Scopes';
  count: Scalars['Int']['output'];
  cursor: Scalars['Int']['output'];
  nodes: Array<Scope>;
};

export type SetInvoiceCanceled = {
  id: Scalars['Int']['input'];
};

export type SetInvoicePaid = {
  id: Scalars['Int']['input'];
};

export type SetPasswordInput = {
  password: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type SubmitQuotaInput = {
  companyId: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  memo?: InputMaybe<Scalars['String']['input']>;
  quota: Scalars['Float']['input'];
  resourceId: Scalars['Int']['input'];
};

export type SystemChartData = {
  __typename?: 'SystemChartData';
  allocated: ChartData;
  totalCompanies: Scalars['Int']['output'];
  usage: ChartData;
};

export type TestTokenInput = {
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyInput = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type UpdateLocalProjectInput = {
  disk: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  memory: Scalars['Int']['input'];
  vcpu: Scalars['Int']['input'];
};

export type UpdateNutanixProjectInput = {
  disk: Scalars['Int']['input'];
  memory: Scalars['Int']['input'];
  projectUuid: Scalars['String']['input'];
  vcpu: Scalars['Int']['input'];
};

export type UpdatePermissionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  roleIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  scopeId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateResourceInput = {
  id: Scalars['Int']['input'];
  max?: InputMaybe<Scalars['Int']['input']>;
  min?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<GuardType>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateResourcePricingInput = {
  id: Scalars['Int']['input'];
  memo: Scalars['String']['input'];
  price: Scalars['Float']['input'];
};

export type UpdateRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  permissionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: GuardType;
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdateScopeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  permissionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: GuardType;
};

export type UpdateUserInput = {
  companyId?: InputMaybe<Scalars['Float']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type User = {
  __typename?: 'User';
  company?: Maybe<Company>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isEmailConfirmed: Scalars['Boolean']['output'];
  isOtpVerified: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  otpSecret?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<UserRoles>>;
  tempPassword?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userName?: Maybe<Scalars['String']['output']>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  roles?: Maybe<Array<UserRoles>>;
  userName: Scalars['String']['output'];
};

export type UserRoles = {
  __typename?: 'UserRoles';
  role?: Maybe<Role>;
  roleId: Scalars['Int']['output'];
  user?: Maybe<User>;
  userId: Scalars['Int']['output'];
};

export type Users = {
  __typename?: 'Users';
  count?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['Int']['output']>;
  nodes?: Maybe<Array<User>>;
};

export type VmEntity = {
  __typename?: 'VMEntity';
  /** IP Address */
  address: Scalars['String']['output'];
  /** Description */
  description: Scalars['String']['output'];
  /** Floating IP */
  floatingIp?: Maybe<FloatingIpEntity>;
  /** Name */
  name: Scalars['String']['output'];
  /** Nic uuid */
  nicUuid: Scalars['String']['output'];
  /** Project Uuid */
  projectUuid: Scalars['String']['output'];
  /** Uuid */
  uuid: Scalars['String']['output'];
};

export type VerifyOtpInput = {
  token: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type VerifyOtpResponse = {
  __typename?: 'VerifyOtpResponse';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type CheckEmailConfirmationMutationVariables = Exact<{
  input: CheckEmailConfirmationInput;
}>;

export type CheckEmailConfirmationMutation = {
  __typename?: 'Mutation';
  checkEmailConfirmation: boolean;
};

export type ConfirmEmailMutationVariables = Exact<{
  input: ConfirmEmailInput;
}>;

export type ConfirmEmailMutation = {
  __typename?: 'Mutation';
  confirmEmail: boolean;
};

export type CreateMemberMutationVariables = Exact<{
  input: CreateMemberInput;
}>;

export type CreateMemberMutation = {
  __typename?: 'Mutation';
  createMember: {
    __typename?: 'UserInfo';
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    userName: string;
    roles?: Array<{
      __typename?: 'UserRoles';
      role?: {
        __typename?: 'Role';
        id: number;
        title: string;
        description?: string | null;
        permissions?: {
          __typename?: 'Permissions';
          cursor?: number | null;
          count?: number | null;
          nodes?: Array<{
            __typename?: 'Permission';
            id: number;
            title: string;
            description?: string | null;
          }> | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type EnableOtpMutationVariables = Exact<{
  input: EnableOtpInput;
}>;

export type EnableOtpMutation = { __typename?: 'Mutation'; enableOtp: boolean };

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;

export type ForgotPasswordMutation = {
  __typename?: 'Mutation';
  forgotPassword: {
    __typename?: 'UserInfo';
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    userName: string;
    roles?: Array<{
      __typename?: 'UserRoles';
      role?: {
        __typename?: 'Role';
        id: number;
        title: string;
        description?: string | null;
        permissions?: {
          __typename?: 'Permissions';
          cursor?: number | null;
          count?: number | null;
          nodes?: Array<{
            __typename?: 'Permission';
            id: number;
            title: string;
            description?: string | null;
          }> | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type GenerateOtpMutationVariables = Exact<{
  input: GenerateOtpInput;
}>;

export type GenerateOtpMutation = {
  __typename?: 'Mutation';
  generateOtp: {
    __typename?: 'GenerateOtpResponse';
    otpSecret: string;
    otpAuthUrl: string;
  };
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'LoginResponse';
    type: LoginType;
    user: {
      __typename?: 'UserInfo';
      id: number;
      email: string;
      firstName: string;
      lastName: string;
      userName: string;
      roles?: Array<{
        __typename?: 'UserRoles';
        role?: {
          __typename?: 'Role';
          id: number;
          title: string;
          description?: string | null;
          permissions?: {
            __typename?: 'Permissions';
            cursor?: number | null;
            count?: number | null;
            nodes?: Array<{
              __typename?: 'Permission';
              id: number;
              title: string;
              description?: string | null;
            }> | null;
          } | null;
        } | null;
      }> | null;
    };
  };
};

export type LogoutMutationVariables = Exact<{
  input: LogoutInput;
}>;

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean };

export type RefreshTokensMutationVariables = Exact<{
  input: RefreshTokensInput;
}>;

export type RefreshTokensMutation = {
  __typename?: 'Mutation';
  refreshTokens: {
    __typename?: 'RefreshTokensResponse';
    accessToken: string;
    refreshToken: string;
  };
};

export type RegisterMutationVariables = Exact<{
  input: RegisterInput;
}>;

export type RegisterMutation = {
  __typename?: 'Mutation';
  register: {
    __typename?: 'UserInfo';
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    userName: string;
    roles?: Array<{
      __typename?: 'UserRoles';
      role?: {
        __typename?: 'Role';
        id: number;
        title: string;
        description?: string | null;
        permissions?: {
          __typename?: 'Permissions';
          cursor?: number | null;
          count?: number | null;
          nodes?: Array<{
            __typename?: 'Permission';
            id: number;
            title: string;
            description?: string | null;
          }> | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type SetPasswordMutationVariables = Exact<{
  input: SetPasswordInput;
}>;

export type SetPasswordMutation = {
  __typename?: 'Mutation';
  setPassword: boolean;
};

export type VerifyOtpMutationVariables = Exact<{
  input: VerifyOtpInput;
}>;

export type VerifyOtpMutation = {
  __typename?: 'Mutation';
  verifyOtp: {
    __typename?: 'VerifyOtpResponse';
    accessToken: string;
    refreshToken: string;
  };
};

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;

export type CreateCompanyMutation = {
  __typename?: 'Mutation';
  createCompany: {
    __typename?: 'Company';
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
  };
};

export type GetCompaniesQueryVariables = Exact<{
  input: GetCompaniesInput;
}>;

export type GetCompaniesQuery = {
  __typename?: 'Query';
  getCompanies: {
    __typename?: 'Companies';
    count?: number | null;
    nodes?: Array<{
      __typename?: 'Company';
      id: number;
      name: string;
      createdAt: string;
      updatedAt: string;
      approvedQuotas?: Array<{
        __typename?: 'Quota';
        id: number;
        memo?: string | null;
        quota: number;
        status: QuotaStatus;
        reviewedBy?: number | null;
        reviewNotes?: string | null;
        createdAt: string;
        updatedAt: string;
        reviewer?: {
          __typename?: 'User';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
        company?: {
          __typename?: 'Company';
          id: number;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        resource?: {
          __typename?: 'Resource';
          id: number;
          name: string;
          unit: string;
          createdAt: string;
          updatedAt: string;
          min: number;
          max: number;
          guardType?: GuardType | null;
        } | null;
      }> | null;
      pendingQuotas?: Array<{
        __typename?: 'Quota';
        id: number;
        memo?: string | null;
        quota: number;
        status: QuotaStatus;
        reviewedBy?: number | null;
        reviewNotes?: string | null;
        createdAt: string;
        updatedAt: string;
        reviewer?: {
          __typename?: 'User';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
        company?: {
          __typename?: 'Company';
          id: number;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        resource?: {
          __typename?: 'Resource';
          id: number;
          name: string;
          unit: string;
          createdAt: string;
          updatedAt: string;
          min: number;
          max: number;
          guardType?: GuardType | null;
        } | null;
      }> | null;
    }> | null;
  };
};

export type GetCompanyQueryVariables = Exact<{
  input: GetCompanyInput;
}>;

export type GetCompanyQuery = {
  __typename?: 'Query';
  getCompany: {
    __typename?: 'Company';
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
    resourcePricings?: Array<{
      __typename?: 'ResourcePricingOnCompany';
      resourcePricing: {
        __typename?: 'ResourcePricing';
        id: number;
        price: number;
        currency?: Currency | null;
        resource?: {
          __typename?: 'Resource';
          id: number;
          name: string;
          unit: string;
        } | null;
      };
    }> | null;
    approvedQuotas?: Array<{
      __typename?: 'Quota';
      id: number;
      memo?: string | null;
      quota: number;
      status: QuotaStatus;
      reviewedBy?: number | null;
      reviewNotes?: string | null;
      createdAt: string;
      updatedAt: string;
      reviewer?: {
        __typename?: 'User';
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      company?: {
        __typename?: 'Company';
        id: number;
        name: string;
        createdAt: string;
        updatedAt: string;
      } | null;
      resource?: {
        __typename?: 'Resource';
        id: number;
        name: string;
        unit: string;
        createdAt: string;
        updatedAt: string;
        min: number;
        max: number;
        guardType?: GuardType | null;
      } | null;
    }> | null;
    pendingQuotas?: Array<{
      __typename?: 'Quota';
      id: number;
      memo?: string | null;
      quota: number;
      status: QuotaStatus;
      reviewedBy?: number | null;
      reviewNotes?: string | null;
      createdAt: string;
      updatedAt: string;
      reviewer?: {
        __typename?: 'User';
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      company?: {
        __typename?: 'Company';
        id: number;
        name: string;
        createdAt: string;
        updatedAt: string;
      } | null;
      resource?: {
        __typename?: 'Resource';
        id: number;
        name: string;
        unit: string;
        createdAt: string;
        updatedAt: string;
        min: number;
        max: number;
        guardType?: GuardType | null;
      } | null;
    }> | null;
  };
};

export type GetCompanyBasicQueryVariables = Exact<{
  input: GetCompanyInput;
}>;

export type GetCompanyBasicQuery = {
  __typename?: 'Query';
  getCompany: {
    __typename?: 'Company';
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
  };
};

export type CompanyFragment = {
  __typename?: 'Company';
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type GetInvoicesQueryVariables = Exact<{
  input: GetInvoicesInput;
}>;

export type GetInvoicesQuery = {
  __typename?: 'Query';
  getInvoices: {
    __typename?: 'Invoices';
    count?: number | null;
    nodes?: Array<{
      __typename?: 'Invoice';
      id: number;
      invoiceNumber: string;
      date: string;
      dueDate: string;
      paidDate?: string | null;
      cancelDate?: string | null;
      totalAmount: number;
      currency: string;
      status: string;
      createdAt: string;
      updatedAt: string;
      company?: { __typename?: 'Company'; id: number; name: string } | null;
    }> | null;
  };
};

export type InvoiceFragment = {
  __typename?: 'Invoice';
  id: number;
  invoiceNumber: string;
  date: string;
  dueDate: string;
  paidDate?: string | null;
  cancelDate?: string | null;
  totalAmount: number;
  currency: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  company?: { __typename?: 'Company'; id: number; name: string } | null;
};

export type SetInvoiceCanceledMutationVariables = Exact<{
  input: SetInvoiceCanceled;
}>;

export type SetInvoiceCanceledMutation = {
  __typename?: 'Mutation';
  setInvoiceCanceled: boolean;
};

export type SetInvoicePaidMutationVariables = Exact<{
  input: SetInvoicePaid;
}>;

export type SetInvoicePaidMutation = {
  __typename?: 'Mutation';
  setInvoicePaid: boolean;
};

export type CreatePermissionMutationVariables = Exact<{
  input: CreatePermissionInput;
}>;

export type CreatePermissionMutation = {
  __typename?: 'Mutation';
  createPermission: {
    __typename?: 'Permission';
    id: number;
    title: string;
    description?: string | null;
    scope?: {
      __typename?: 'Scope';
      id: number;
      title: string;
      description?: string | null;
    } | null;
    roles?: {
      __typename?: 'Roles';
      cursor?: number | null;
      count?: number | null;
      nodes?: Array<{
        __typename?: 'Role';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type DeletePermissionMutationVariables = Exact<{
  input: DeletePermissionInput;
}>;

export type DeletePermissionMutation = {
  __typename?: 'Mutation';
  deletePermission: boolean;
};

export type GetPermissionQueryVariables = Exact<{
  input: GetPermissionInput;
}>;

export type GetPermissionQuery = {
  __typename?: 'Query';
  getPermission: {
    __typename?: 'Permission';
    id: number;
    title: string;
    description?: string | null;
    scope?: {
      __typename?: 'Scope';
      id: number;
      title: string;
      description?: string | null;
    } | null;
    roles?: {
      __typename?: 'Roles';
      cursor?: number | null;
      count?: number | null;
      nodes?: Array<{
        __typename?: 'Role';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type GetPermissionsQueryVariables = Exact<{
  input: GetPermissionsInput;
}>;

export type GetPermissionsQuery = {
  __typename?: 'Query';
  getPermissions: {
    __typename?: 'Permissions';
    cursor?: number | null;
    count?: number | null;
    nodes?: Array<{
      __typename?: 'Permission';
      id: number;
      title: string;
      description?: string | null;
      scope?: {
        __typename?: 'Scope';
        id: number;
        title: string;
        description?: string | null;
      } | null;
      roles?: {
        __typename?: 'Roles';
        cursor?: number | null;
        count?: number | null;
        nodes?: Array<{
          __typename?: 'Role';
          description?: string | null;
          id: number;
          title: string;
        }> | null;
      } | null;
    }> | null;
  };
};

export type PermissionFragment = {
  __typename?: 'Permission';
  id: number;
  title: string;
  description?: string | null;
  scope?: {
    __typename?: 'Scope';
    id: number;
    title: string;
    description?: string | null;
  } | null;
  roles?: {
    __typename?: 'Roles';
    cursor?: number | null;
    count?: number | null;
    nodes?: Array<{
      __typename?: 'Role';
      description?: string | null;
      id: number;
      title: string;
    }> | null;
  } | null;
};

export type UpdatePermissionMutationVariables = Exact<{
  input: UpdatePermissionInput;
}>;

export type UpdatePermissionMutation = {
  __typename?: 'Mutation';
  updatePermission: {
    __typename?: 'Permission';
    id: number;
    title: string;
    description?: string | null;
    scope?: {
      __typename?: 'Scope';
      id: number;
      title: string;
      description?: string | null;
    } | null;
    roles?: {
      __typename?: 'Roles';
      cursor?: number | null;
      count?: number | null;
      nodes?: Array<{
        __typename?: 'Role';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type CreateProjectMutationVariables = Exact<{
  input: CreateNutanixProjectInput;
}>;

export type CreateProjectMutation = {
  __typename?: 'Mutation';
  createProject: boolean;
};

export type GetLocalProjectsQueryVariables = Exact<{
  input: GetLocalProjectsInput;
}>;

export type GetLocalProjectsQuery = {
  __typename?: 'Query';
  getLocalProjects: {
    __typename?: 'LocalProjects';
    cursor?: number | null;
    count?: number | null;
    nodes?: Array<{
      __typename?: 'LocalProject';
      id: number;
      projectName: string;
      projectUuid: string;
      username: string;
      userUuid: string;
      createdAt: string;
      removingMark: boolean;
      resourceQuotas?: Array<{
        __typename?: 'Quota';
        quota: number;
        resource?: {
          __typename?: 'Resource';
          name: string;
          unit: string;
        } | null;
      }> | null;
    }> | null;
  };
};

export type LocalProjectFragment = {
  __typename?: 'LocalProject';
  id: number;
  projectName: string;
  projectUuid: string;
  username: string;
  userUuid: string;
  createdAt: string;
  removingMark: boolean;
  resourceQuotas?: Array<{
    __typename?: 'Quota';
    quota: number;
    resource?: { __typename?: 'Resource'; name: string; unit: string } | null;
  }> | null;
};

export type MarkLocalProjectMutationVariables = Exact<{
  input: MarkLocalProjectInput;
}>;

export type MarkLocalProjectMutation = {
  __typename?: 'Mutation';
  markLocalProject: {
    __typename?: 'LocalProject';
    id: number;
    projectName: string;
    projectUuid: string;
    username: string;
    userUuid: string;
    createdAt: string;
    removingMark: boolean;
    resourceQuotas?: Array<{
      __typename?: 'Quota';
      quota: number;
      resource?: { __typename?: 'Resource'; name: string; unit: string } | null;
    }> | null;
  };
};

export type RemoveProjectMutationVariables = Exact<{
  input: RemoveNutanixProjectInput;
}>;

export type RemoveProjectMutation = {
  __typename?: 'Mutation';
  removeProject: boolean;
};

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateNutanixProjectInput;
}>;

export type UpdateProjectMutation = {
  __typename?: 'Mutation';
  updateProject: boolean;
};

export type ApproveQuotaMutationVariables = Exact<{
  input: ApproveQuotaInput;
}>;

export type ApproveQuotaMutation = {
  __typename?: 'Mutation';
  approveQuota: {
    __typename?: 'Quota';
    id: number;
    memo?: string | null;
    quota: number;
    status: QuotaStatus;
    reviewedBy?: number | null;
    reviewNotes?: string | null;
    createdAt: string;
    updatedAt: string;
    reviewer?: {
      __typename?: 'User';
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    company?: {
      __typename?: 'Company';
      id: number;
      name: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    resource?: {
      __typename?: 'Resource';
      id: number;
      name: string;
      unit: string;
      createdAt: string;
      updatedAt: string;
      min: number;
      max: number;
      guardType?: GuardType | null;
    } | null;
  };
};

export type CreateQuotaMutationVariables = Exact<{
  input: CreateQuotaInput;
}>;

export type CreateQuotaMutation = {
  __typename?: 'Mutation';
  createQuota: {
    __typename?: 'Quota';
    id: number;
    memo?: string | null;
    quota: number;
    status: QuotaStatus;
    reviewedBy?: number | null;
    reviewNotes?: string | null;
    createdAt: string;
    updatedAt: string;
    reviewer?: {
      __typename?: 'User';
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    company?: {
      __typename?: 'Company';
      id: number;
      name: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    resource?: {
      __typename?: 'Resource';
      id: number;
      name: string;
      unit: string;
      createdAt: string;
      updatedAt: string;
      min: number;
      max: number;
      guardType?: GuardType | null;
    } | null;
  };
};

export type GetQuotasQueryVariables = Exact<{
  input: GetQuotasInput;
}>;

export type GetQuotasQuery = {
  __typename?: 'Query';
  getQuotas: {
    __typename?: 'Quotas';
    count?: number | null;
    cursor?: number | null;
    nodes?: Array<{
      __typename?: 'Quota';
      id: number;
      memo?: string | null;
      quota: number;
      status: QuotaStatus;
      reviewedBy?: number | null;
      reviewNotes?: string | null;
      createdAt: string;
      updatedAt: string;
      reviewer?: {
        __typename?: 'User';
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      company?: {
        __typename?: 'Company';
        id: number;
        name: string;
        createdAt: string;
        updatedAt: string;
      } | null;
      resource?: {
        __typename?: 'Resource';
        id: number;
        name: string;
        unit: string;
        createdAt: string;
        updatedAt: string;
        min: number;
        max: number;
        guardType?: GuardType | null;
      } | null;
    }> | null;
  };
};

export type QuotaFragment = {
  __typename?: 'Quota';
  id: number;
  memo?: string | null;
  quota: number;
  status: QuotaStatus;
  reviewedBy?: number | null;
  reviewNotes?: string | null;
  createdAt: string;
  updatedAt: string;
  reviewer?: {
    __typename?: 'User';
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  company?: {
    __typename?: 'Company';
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  resource?: {
    __typename?: 'Resource';
    id: number;
    name: string;
    unit: string;
    createdAt: string;
    updatedAt: string;
    min: number;
    max: number;
    guardType?: GuardType | null;
  } | null;
};

export type RejectQuotaMutationVariables = Exact<{
  input: RejectQuotaInput;
}>;

export type RejectQuotaMutation = {
  __typename?: 'Mutation';
  rejectQuota: {
    __typename?: 'Quota';
    id: number;
    memo?: string | null;
    quota: number;
    status: QuotaStatus;
    reviewedBy?: number | null;
    reviewNotes?: string | null;
    createdAt: string;
    updatedAt: string;
    reviewer?: {
      __typename?: 'User';
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    company?: {
      __typename?: 'Company';
      id: number;
      name: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    resource?: {
      __typename?: 'Resource';
      id: number;
      name: string;
      unit: string;
      createdAt: string;
      updatedAt: string;
      min: number;
      max: number;
      guardType?: GuardType | null;
    } | null;
  };
};

export type SubmitQuotaMutationVariables = Exact<{
  input: SubmitQuotaInput;
}>;

export type SubmitQuotaMutation = {
  __typename?: 'Mutation';
  submitQuota: {
    __typename?: 'Quota';
    id: number;
    memo?: string | null;
    quota: number;
    status: QuotaStatus;
    reviewedBy?: number | null;
    reviewNotes?: string | null;
    createdAt: string;
    updatedAt: string;
    reviewer?: {
      __typename?: 'User';
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    company?: {
      __typename?: 'Company';
      id: number;
      name: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    resource?: {
      __typename?: 'Resource';
      id: number;
      name: string;
      unit: string;
      createdAt: string;
      updatedAt: string;
      min: number;
      max: number;
      guardType?: GuardType | null;
    } | null;
  };
};

export type ApplyResourcePricingMutationVariables = Exact<{
  input: ApplyResourcePricingInput;
}>;

export type ApplyResourcePricingMutation = {
  __typename?: 'Mutation';
  applyResourcePricing: {
    __typename?: 'ResourcePricing';
    id: number;
    price: number;
    createdAt: string;
    updatedAt: string;
    memo?: string | null;
    currency?: Currency | null;
    resource?: {
      __typename?: 'Resource';
      id: number;
      name: string;
      unit: string;
      createdAt: string;
      updatedAt: string;
      min: number;
      max: number;
      guardType?: GuardType | null;
    } | null;
  };
};

export type CreateResourcePricingMutationVariables = Exact<{
  input: CreateResourcePricingInput;
}>;

export type CreateResourcePricingMutation = {
  __typename?: 'Mutation';
  createResourcePricing: {
    __typename?: 'ResourcePricing';
    id: number;
    price: number;
    createdAt: string;
    updatedAt: string;
    memo?: string | null;
    currency?: Currency | null;
    resource?: {
      __typename?: 'Resource';
      id: number;
      name: string;
      unit: string;
      createdAt: string;
      updatedAt: string;
      min: number;
      max: number;
      guardType?: GuardType | null;
    } | null;
  };
};

export type GetDailyResourceCostForOrganizationQueryVariables = Exact<{
  input: GetDailyResourceCostForOrganizationInput;
}>;

export type GetDailyResourceCostForOrganizationQuery = {
  __typename?: 'Query';
  getDailyResourceCostForOrganization: Array<{
    __typename?: 'DailyResourceCost';
    day: string;
    totalCost: number;
    resourceCosts: Array<{
      __typename?: 'ResourceCost';
      resourceName: string;
      cost: number;
      quota: number;
      unit: string;
      pricePerUnit: number;
    }>;
  }>;
};

export type GetMonthlyCostForOrganizationQueryVariables = Exact<{
  input: GetMonthlyCostForOrganizationInput;
}>;

export type GetMonthlyCostForOrganizationQuery = {
  __typename?: 'Query';
  getMonthlyCostForOrganization: Array<{
    __typename?: 'MonthlyCost';
    month: string;
    totalCost: number;
  }>;
};

export type GetResourcePricingQueryVariables = Exact<{
  input: GetResourcePricingInput;
}>;

export type GetResourcePricingQuery = {
  __typename?: 'Query';
  getResourcePricing: {
    __typename?: 'ResourcePricing';
    id: number;
    price: number;
    createdAt: string;
    updatedAt: string;
    memo?: string | null;
    currency?: Currency | null;
    resource?: {
      __typename?: 'Resource';
      id: number;
      name: string;
      unit: string;
      createdAt: string;
      updatedAt: string;
      min: number;
      max: number;
      guardType?: GuardType | null;
    } | null;
  };
};

export type GetResourcePricingsQueryVariables = Exact<{
  input: GetResourcePricingsInput;
}>;

export type GetResourcePricingsQuery = {
  __typename?: 'Query';
  getResourcePricings: {
    __typename?: 'ResourcePricings';
    count?: number | null;
    cursor?: number | null;
    nodes?: Array<{
      __typename?: 'ResourcePricing';
      id: number;
      price: number;
      createdAt: string;
      updatedAt: string;
      memo?: string | null;
      currency?: Currency | null;
      resource?: {
        __typename?: 'Resource';
        id: number;
        name: string;
        unit: string;
        createdAt: string;
        updatedAt: string;
        min: number;
        max: number;
        guardType?: GuardType | null;
      } | null;
    }> | null;
  };
};

export type ResourcePricingFragment = {
  __typename?: 'ResourcePricing';
  id: number;
  price: number;
  createdAt: string;
  updatedAt: string;
  memo?: string | null;
  currency?: Currency | null;
  resource?: {
    __typename?: 'Resource';
    id: number;
    name: string;
    unit: string;
    createdAt: string;
    updatedAt: string;
    min: number;
    max: number;
    guardType?: GuardType | null;
  } | null;
};

export type UpdateResourcePricingMutationVariables = Exact<{
  input: UpdateResourcePricingInput;
}>;

export type UpdateResourcePricingMutation = {
  __typename?: 'Mutation';
  updateResourcePricing: {
    __typename?: 'ResourcePricing';
    id: number;
    price: number;
    createdAt: string;
    updatedAt: string;
    memo?: string | null;
    currency?: Currency | null;
    resource?: {
      __typename?: 'Resource';
      id: number;
      name: string;
      unit: string;
      createdAt: string;
      updatedAt: string;
      min: number;
      max: number;
      guardType?: GuardType | null;
    } | null;
  };
};

export type CreateResourceMutationVariables = Exact<{
  input: CreateResourceInput;
}>;

export type CreateResourceMutation = {
  __typename?: 'Mutation';
  createResource: {
    __typename?: 'Resource';
    id: number;
    name: string;
    unit: string;
    createdAt: string;
    updatedAt: string;
    min: number;
    max: number;
    guardType?: GuardType | null;
  };
};

export type GetResourceQueryVariables = Exact<{
  input: GetResourceInput;
}>;

export type GetResourceQuery = {
  __typename?: 'Query';
  getResource: {
    __typename?: 'Resource';
    id: number;
    name: string;
    unit: string;
    createdAt: string;
    updatedAt: string;
    min: number;
    max: number;
    guardType?: GuardType | null;
  };
};

export type GetResourcesQueryVariables = Exact<{
  input: GetResourcesInput;
}>;

export type GetResourcesQuery = {
  __typename?: 'Query';
  getResources: {
    __typename?: 'Resources';
    count?: number | null;
    cursor?: number | null;
    nodes?: Array<{
      __typename?: 'Resource';
      id: number;
      name: string;
      unit: string;
      createdAt: string;
      updatedAt: string;
      min: number;
      max: number;
      guardType?: GuardType | null;
    }> | null;
  };
};

export type ResourceFragment = {
  __typename?: 'Resource';
  id: number;
  name: string;
  unit: string;
  createdAt: string;
  updatedAt: string;
  min: number;
  max: number;
  guardType?: GuardType | null;
};

export type UpdateResourceMutationVariables = Exact<{
  input: UpdateResourceInput;
}>;

export type UpdateResourceMutation = {
  __typename?: 'Mutation';
  updateResource: {
    __typename?: 'Resource';
    id: number;
    name: string;
    unit: string;
    createdAt: string;
    updatedAt: string;
    min: number;
    max: number;
    guardType?: GuardType | null;
  };
};

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleInput;
}>;

export type CreateRoleMutation = {
  __typename?: 'Mutation';
  createRole: {
    __typename?: 'Role';
    id: number;
    title: string;
    description?: string | null;
    type?: GuardType | null;
    permissions?: {
      __typename?: 'Permissions';
      count?: number | null;
      cursor?: number | null;
      nodes?: Array<{
        __typename?: 'Permission';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type DeleteRoleMutationVariables = Exact<{
  input: DeleteRoleInput;
}>;

export type DeleteRoleMutation = {
  __typename?: 'Mutation';
  deleteRole: boolean;
};

export type GetRoleQueryVariables = Exact<{
  input: GetRoleInput;
}>;

export type GetRoleQuery = {
  __typename?: 'Query';
  getRole: {
    __typename?: 'Role';
    id: number;
    title: string;
    description?: string | null;
    type?: GuardType | null;
    permissions?: {
      __typename?: 'Permissions';
      count?: number | null;
      cursor?: number | null;
      nodes?: Array<{
        __typename?: 'Permission';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type GetRolesQueryVariables = Exact<{
  input: GetRolesInput;
}>;

export type GetRolesQuery = {
  __typename?: 'Query';
  getRoles: {
    __typename?: 'Roles';
    count?: number | null;
    cursor?: number | null;
    nodes?: Array<{
      __typename?: 'Role';
      id: number;
      title: string;
      description?: string | null;
      type?: GuardType | null;
      permissions?: {
        __typename?: 'Permissions';
        count?: number | null;
        cursor?: number | null;
        nodes?: Array<{
          __typename?: 'Permission';
          description?: string | null;
          id: number;
          title: string;
        }> | null;
      } | null;
    }> | null;
  };
};

export type RoleFragment = {
  __typename?: 'Role';
  id: number;
  title: string;
  description?: string | null;
  type?: GuardType | null;
  permissions?: {
    __typename?: 'Permissions';
    count?: number | null;
    cursor?: number | null;
    nodes?: Array<{
      __typename?: 'Permission';
      description?: string | null;
      id: number;
      title: string;
    }> | null;
  } | null;
};

export type UpdateRoleMutationVariables = Exact<{
  input: UpdateRoleInput;
}>;

export type UpdateRoleMutation = {
  __typename?: 'Mutation';
  updateRole: {
    __typename?: 'Role';
    id: number;
    title: string;
    description?: string | null;
    type?: GuardType | null;
    permissions?: {
      __typename?: 'Permissions';
      count?: number | null;
      cursor?: number | null;
      nodes?: Array<{
        __typename?: 'Permission';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type CreateScopeMutationVariables = Exact<{
  input: CreateScopeInput;
}>;

export type CreateScopeMutation = {
  __typename?: 'Mutation';
  createScope: {
    __typename?: 'Scope';
    id: number;
    title: string;
    description?: string | null;
    type?: GuardType | null;
    permissions?: {
      __typename?: 'Permissions';
      cursor?: number | null;
      count?: number | null;
      nodes?: Array<{
        __typename?: 'Permission';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type DeleteScopeMutationVariables = Exact<{
  input: DeleteScopeInput;
}>;

export type DeleteScopeMutation = {
  __typename?: 'Mutation';
  deleteScope: boolean;
};

export type GetScopeQueryVariables = Exact<{
  input: GetScopeInput;
}>;

export type GetScopeQuery = {
  __typename?: 'Query';
  getScope: {
    __typename?: 'Scope';
    id: number;
    title: string;
    description?: string | null;
    type?: GuardType | null;
    permissions?: {
      __typename?: 'Permissions';
      cursor?: number | null;
      count?: number | null;
      nodes?: Array<{
        __typename?: 'Permission';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type GetScopesQueryVariables = Exact<{
  input: GetScopesInput;
}>;

export type GetScopesQuery = {
  __typename?: 'Query';
  getScopes: {
    __typename?: 'Scopes';
    count: number;
    cursor: number;
    nodes: Array<{
      __typename?: 'Scope';
      id: number;
      title: string;
      description?: string | null;
      type?: GuardType | null;
      permissions?: {
        __typename?: 'Permissions';
        cursor?: number | null;
        count?: number | null;
        nodes?: Array<{
          __typename?: 'Permission';
          description?: string | null;
          id: number;
          title: string;
        }> | null;
      } | null;
    }>;
  };
};

export type ScopeFragment = {
  __typename?: 'Scope';
  id: number;
  title: string;
  description?: string | null;
  type?: GuardType | null;
  permissions?: {
    __typename?: 'Permissions';
    cursor?: number | null;
    count?: number | null;
    nodes?: Array<{
      __typename?: 'Permission';
      description?: string | null;
      id: number;
      title: string;
    }> | null;
  } | null;
};

export type UpdateScopeMutationVariables = Exact<{
  input: UpdateScopeInput;
}>;

export type UpdateScopeMutation = {
  __typename?: 'Mutation';
  updateScope: {
    __typename?: 'Scope';
    id: number;
    title: string;
    description?: string | null;
    type?: GuardType | null;
    permissions?: {
      __typename?: 'Permissions';
      cursor?: number | null;
      count?: number | null;
      nodes?: Array<{
        __typename?: 'Permission';
        description?: string | null;
        id: number;
        title: string;
      }> | null;
    } | null;
  };
};

export type CheckUserEmailQueryVariables = Exact<{
  input: CheckUserEmailInput;
}>;

export type CheckUserEmailQuery = {
  __typename?: 'Query';
  checkUserEmail: boolean;
};

export type CheckUserUsernameQueryVariables = Exact<{
  input: CheckUserUsernameInput;
}>;

export type CheckUserUsernameQuery = {
  __typename?: 'Query';
  checkUserUsername: boolean;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'User';
    id: number;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    userName?: string | null;
    password?: string | null;
    tempPassword?: string | null;
    isEmailConfirmed: boolean;
    otpSecret?: string | null;
    refreshToken?: string | null;
    createdAt: string;
    updatedAt: string;
    roles?: Array<{
      __typename?: 'UserRoles';
      role?: {
        __typename?: 'Role';
        id: number;
        title: string;
        description?: string | null;
        permissions?: {
          __typename?: 'Permissions';
          nodes?: Array<{
            __typename?: 'Permission';
            id: number;
            title: string;
            description?: string | null;
          }> | null;
        } | null;
      } | null;
    }> | null;
    company?: {
      __typename?: 'Company';
      id: number;
      name: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;

export type DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser: boolean;
};

export type GetMembersQueryVariables = Exact<{
  input: GetMembersInput;
}>;

export type GetMembersQuery = {
  __typename?: 'Query';
  getMembers: {
    __typename?: 'Users';
    count?: number | null;
    cursor?: number | null;
    nodes?: Array<{
      __typename?: 'User';
      id: number;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      userName?: string | null;
      password?: string | null;
      tempPassword?: string | null;
      isEmailConfirmed: boolean;
      otpSecret?: string | null;
      refreshToken?: string | null;
      createdAt: string;
      updatedAt: string;
      roles?: Array<{
        __typename?: 'UserRoles';
        role?: {
          __typename?: 'Role';
          id: number;
          title: string;
          description?: string | null;
          permissions?: {
            __typename?: 'Permissions';
            nodes?: Array<{
              __typename?: 'Permission';
              id: number;
              title: string;
              description?: string | null;
            }> | null;
          } | null;
        } | null;
      }> | null;
      company?: {
        __typename?: 'Company';
        id: number;
        name: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    }> | null;
  };
};

export type GetUserQueryVariables = Exact<{
  input: GetUserInput;
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'User';
    id: number;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    userName?: string | null;
    password?: string | null;
    tempPassword?: string | null;
    isEmailConfirmed: boolean;
    otpSecret?: string | null;
    refreshToken?: string | null;
    createdAt: string;
    updatedAt: string;
    roles?: Array<{
      __typename?: 'UserRoles';
      role?: {
        __typename?: 'Role';
        id: number;
        title: string;
        description?: string | null;
        permissions?: {
          __typename?: 'Permissions';
          nodes?: Array<{
            __typename?: 'Permission';
            id: number;
            title: string;
            description?: string | null;
          }> | null;
        } | null;
      } | null;
    }> | null;
    company?: {
      __typename?: 'Company';
      id: number;
      name: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type GetUsersQueryVariables = Exact<{
  input: GetUsersInput;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  getUsers: {
    __typename?: 'Users';
    count?: number | null;
    cursor?: number | null;
    nodes?: Array<{
      __typename?: 'User';
      id: number;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      userName?: string | null;
      password?: string | null;
      tempPassword?: string | null;
      isEmailConfirmed: boolean;
      otpSecret?: string | null;
      refreshToken?: string | null;
      createdAt: string;
      updatedAt: string;
      roles?: Array<{
        __typename?: 'UserRoles';
        role?: {
          __typename?: 'Role';
          id: number;
          title: string;
          description?: string | null;
          permissions?: {
            __typename?: 'Permissions';
            nodes?: Array<{
              __typename?: 'Permission';
              id: number;
              title: string;
              description?: string | null;
            }> | null;
          } | null;
        } | null;
      }> | null;
      company?: {
        __typename?: 'Company';
        id: number;
        name: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    }> | null;
  };
};

export type UserFragment = {
  __typename?: 'User';
  id: number;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  userName?: string | null;
  password?: string | null;
  tempPassword?: string | null;
  isEmailConfirmed: boolean;
  otpSecret?: string | null;
  refreshToken?: string | null;
  createdAt: string;
  updatedAt: string;
  roles?: Array<{
    __typename?: 'UserRoles';
    role?: {
      __typename?: 'Role';
      id: number;
      title: string;
      description?: string | null;
      permissions?: {
        __typename?: 'Permissions';
        nodes?: Array<{
          __typename?: 'Permission';
          id: number;
          title: string;
          description?: string | null;
        }> | null;
      } | null;
    } | null;
  }> | null;
  company?: {
    __typename?: 'Company';
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UserInfoFragment = {
  __typename?: 'UserInfo';
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  userName: string;
  roles?: Array<{
    __typename?: 'UserRoles';
    role?: {
      __typename?: 'Role';
      id: number;
      title: string;
      description?: string | null;
      permissions?: {
        __typename?: 'Permissions';
        cursor?: number | null;
        count?: number | null;
        nodes?: Array<{
          __typename?: 'Permission';
          id: number;
          title: string;
          description?: string | null;
        }> | null;
      } | null;
    } | null;
  }> | null;
};

export type UpdateSelfUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateSelfUserMutation = {
  __typename?: 'Mutation';
  updateSelfUser: {
    __typename?: 'User';
    id: number;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    userName?: string | null;
    password?: string | null;
    tempPassword?: string | null;
    isEmailConfirmed: boolean;
    otpSecret?: string | null;
    refreshToken?: string | null;
    createdAt: string;
    updatedAt: string;
    roles?: Array<{
      __typename?: 'UserRoles';
      role?: {
        __typename?: 'Role';
        id: number;
        title: string;
        description?: string | null;
        permissions?: {
          __typename?: 'Permissions';
          nodes?: Array<{
            __typename?: 'Permission';
            id: number;
            title: string;
            description?: string | null;
          }> | null;
        } | null;
      } | null;
    }> | null;
    company?: {
      __typename?: 'Company';
      id: number;
      name: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: number;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    userName?: string | null;
    password?: string | null;
    tempPassword?: string | null;
    isEmailConfirmed: boolean;
    otpSecret?: string | null;
    refreshToken?: string | null;
    createdAt: string;
    updatedAt: string;
    roles?: Array<{
      __typename?: 'UserRoles';
      role?: {
        __typename?: 'Role';
        id: number;
        title: string;
        description?: string | null;
        permissions?: {
          __typename?: 'Permissions';
          nodes?: Array<{
            __typename?: 'Permission';
            id: number;
            title: string;
            description?: string | null;
          }> | null;
        } | null;
      } | null;
    }> | null;
    company?: {
      __typename?: 'Company';
      id: number;
      name: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type AssignFloatingIpMutationVariables = Exact<{
  input: AssignFloatingIpInput;
}>;

export type AssignFloatingIpMutation = {
  __typename?: 'Mutation';
  assignFloatingIp: boolean;
};

export type ClearFloatingIpMutationVariables = Exact<{
  input: ClearFloatingIpInput;
}>;

export type ClearFloatingIpMutation = {
  __typename?: 'Mutation';
  clearFloatingIp: boolean;
};

export type GetVmsQueryVariables = Exact<{
  input: GetVmsInput;
}>;

export type GetVmsQuery = {
  __typename?: 'Query';
  getVms: {
    __typename?: 'GetVmsResponse';
    allocated: number;
    usage: number;
    entities: Array<{
      __typename?: 'VMEntity';
      uuid: string;
      name: string;
      address: string;
      description: string;
      nicUuid: string;
      projectUuid: string;
      floatingIp?: {
        __typename?: 'FloatingIpEntity';
        extId: string;
        floatingIp: {
          __typename?: 'FloatingIp';
          ipv4: { __typename?: 'IPv4'; value: string };
        };
      } | null;
    }>;
  };
};

export type VmEntityFragment = {
  __typename?: 'VMEntity';
  uuid: string;
  name: string;
  address: string;
  description: string;
  nicUuid: string;
  projectUuid: string;
  floatingIp?: {
    __typename?: 'FloatingIpEntity';
    extId: string;
    floatingIp: {
      __typename?: 'FloatingIp';
      ipv4: { __typename?: 'IPv4'; value: string };
    };
  } | null;
};

export type GetSystemChartDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetSystemChartDataQuery = {
  __typename?: 'Query';
  getSystemChartData: {
    __typename?: 'SystemChartData';
    totalCompanies: number;
    allocated: {
      __typename?: 'ChartData';
      vcpu: number;
      disk: number;
      memory: number;
      publicIps?: number | null;
    };
    usage: {
      __typename?: 'ChartData';
      vcpu: number;
      disk: number;
      memory: number;
    };
  };
};

export type GetCompanyChartDataQueryVariables = Exact<{
  input: GetCompanyChartData;
}>;

export type GetCompanyChartDataQuery = {
  __typename?: 'Query';
  getCompanyChartData: {
    __typename?: 'CompanyChartData';
    userCount: number;
    projects: Array<{
      __typename?: 'LocalProject';
      projectName: string;
      projectUuid: string;
      username: string;
      userUuid: string;
      createdAt: string;
    }>;
    allocated: {
      __typename?: 'ChartData';
      vcpu: number;
      disk: number;
      memory: number;
      publicIps?: number | null;
    };
    usage: {
      __typename?: 'ChartData';
      vcpu: number;
      disk: number;
      memory: number;
      publicIps?: number | null;
    };
  };
};

export type GetProjectsChartDataQueryVariables = Exact<{
  input: GetProjectsChartData;
}>;

export type GetProjectsChartDataQuery = {
  __typename?: 'Query';
  getProjectsChartData: {
    __typename?: 'ProjectsChartData';
    allocated: {
      __typename?: 'ChartData';
      vcpu: number;
      disk: number;
      memory: number;
      publicIps?: number | null;
    };
    usage: {
      __typename?: 'ChartData';
      vcpu: number;
      disk: number;
      memory: number;
      publicIps?: number | null;
    };
  };
};

export const InvoiceFragmentDoc = gql`
  fragment invoice on Invoice {
    id
    invoiceNumber
    date
    dueDate
    paidDate
    cancelDate
    totalAmount
    currency
    status
    createdAt
    updatedAt
    company {
      id
      name
    }
  }
`;
export const PermissionFragmentDoc = gql`
  fragment permission on Permission {
    id
    title
    description
    scope {
      id
      title
      description
    }
    roles {
      cursor
      count
      nodes {
        description
        id
        title
      }
    }
  }
`;
export const LocalProjectFragmentDoc = gql`
  fragment localProject on LocalProject {
    id
    projectName
    projectUuid
    username
    userUuid
    createdAt
    removingMark
    resourceQuotas {
      quota
      resource {
        name
        unit
      }
    }
  }
`;
export const CompanyFragmentDoc = gql`
  fragment company on Company {
    id
    name
    createdAt
    updatedAt
  }
`;
export const ResourceFragmentDoc = gql`
  fragment resource on Resource {
    id
    name
    unit
    createdAt
    updatedAt
    min
    max
    guardType
  }
`;
export const QuotaFragmentDoc = gql`
  fragment quota on Quota {
    id
    memo
    quota
    status
    reviewedBy
    reviewNotes
    createdAt
    updatedAt
    reviewer {
      firstName
      lastName
    }
    company {
      ...company
    }
    resource {
      ...resource
    }
  }
  ${CompanyFragmentDoc}
  ${ResourceFragmentDoc}
`;
export const ResourcePricingFragmentDoc = gql`
  fragment resourcePricing on ResourcePricing {
    id
    price
    createdAt
    updatedAt
    memo
    currency
    resource {
      ...resource
    }
  }
  ${ResourceFragmentDoc}
`;
export const RoleFragmentDoc = gql`
  fragment role on Role {
    id
    title
    description
    type
    permissions {
      count
      cursor
      nodes {
        description
        id
        title
      }
    }
  }
`;
export const ScopeFragmentDoc = gql`
  fragment scope on Scope {
    id
    title
    description
    type
    permissions {
      cursor
      count
      nodes {
        description
        id
        title
      }
    }
  }
`;
export const UserFragmentDoc = gql`
  fragment user on User {
    id
    email
    firstName
    lastName
    userName
    password
    tempPassword
    isEmailConfirmed
    otpSecret
    refreshToken
    createdAt
    updatedAt
    roles {
      role {
        id
        title
        description
        permissions {
          nodes {
            id
            title
            description
          }
        }
      }
    }
    company {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const UserInfoFragmentDoc = gql`
  fragment userInfo on UserInfo {
    id
    email
    firstName
    lastName
    userName
    roles {
      role {
        id
        title
        description
        permissions {
          cursor
          count
          nodes {
            id
            title
            description
          }
        }
      }
    }
  }
`;
export const VmEntityFragmentDoc = gql`
  fragment vmEntity on VMEntity {
    uuid
    name
    address
    description
    nicUuid
    floatingIp {
      extId
      floatingIp {
        ipv4 {
          value
        }
      }
    }
    projectUuid
  }
`;
export const CheckEmailConfirmationDocument = gql`
  mutation checkEmailConfirmation($input: CheckEmailConfirmationInput!) {
    checkEmailConfirmation(input: $input)
  }
`;
export const ConfirmEmailDocument = gql`
  mutation confirmEmail($input: ConfirmEmailInput!) {
    confirmEmail(input: $input)
  }
`;
export const CreateMemberDocument = gql`
  mutation createMember($input: CreateMemberInput!) {
    createMember(input: $input) {
      ...userInfo
    }
  }
  ${UserInfoFragmentDoc}
`;
export const EnableOtpDocument = gql`
  mutation enableOtp($input: EnableOtpInput!) {
    enableOtp(input: $input)
  }
`;
export const ForgotPasswordDocument = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      ...userInfo
    }
  }
  ${UserInfoFragmentDoc}
`;
export const GenerateOtpDocument = gql`
  mutation generateOtp($input: GenerateOtpInput!) {
    generateOtp(input: $input) {
      otpSecret
      otpAuthUrl
    }
  }
`;
export const LoginDocument = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      type
      user {
        ...userInfo
      }
    }
  }
  ${UserInfoFragmentDoc}
`;
export const LogoutDocument = gql`
  mutation logout($input: LogoutInput!) {
    logout(input: $input)
  }
`;
export const RefreshTokensDocument = gql`
  mutation refreshTokens($input: RefreshTokensInput!) {
    refreshTokens(input: $input) {
      accessToken
      refreshToken
    }
  }
`;
export const RegisterDocument = gql`
  mutation register($input: RegisterInput!) {
    register(input: $input) {
      ...userInfo
    }
  }
  ${UserInfoFragmentDoc}
`;
export const SetPasswordDocument = gql`
  mutation setPassword($input: SetPasswordInput!) {
    setPassword(input: $input)
  }
`;
export const VerifyOtpDocument = gql`
  mutation verifyOtp($input: VerifyOtpInput!) {
    verifyOtp(input: $input) {
      accessToken
      refreshToken
    }
  }
`;
export const CreateCompanyDocument = gql`
  mutation createCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      ...company
    }
  }
  ${CompanyFragmentDoc}
`;
export const GetCompaniesDocument = gql`
  query getCompanies($input: GetCompaniesInput!) {
    getCompanies(input: $input) {
      count
      nodes {
        id
        name
        createdAt
        updatedAt
        approvedQuotas {
          ...quota
        }
        pendingQuotas {
          ...quota
        }
      }
    }
  }
  ${QuotaFragmentDoc}
`;
export const GetCompanyDocument = gql`
  query getCompany($input: GetCompanyInput!) {
    getCompany(input: $input) {
      id
      name
      createdAt
      updatedAt
      resourcePricings {
        resourcePricing {
          id
          price
          currency
          resource {
            id
            name
            unit
          }
        }
      }
      approvedQuotas {
        ...quota
      }
      pendingQuotas {
        ...quota
      }
    }
  }
  ${QuotaFragmentDoc}
`;
export const GetCompanyBasicDocument = gql`
  query getCompanyBasic($input: GetCompanyInput!) {
    getCompany(input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const GetInvoicesDocument = gql`
  query getInvoices($input: GetInvoicesInput!) {
    getInvoices(input: $input) {
      count
      nodes {
        ...invoice
      }
    }
  }
  ${InvoiceFragmentDoc}
`;
export const SetInvoiceCanceledDocument = gql`
  mutation setInvoiceCanceled($input: SetInvoiceCanceled!) {
    setInvoiceCanceled(input: $input)
  }
`;
export const SetInvoicePaidDocument = gql`
  mutation setInvoicePaid($input: SetInvoicePaid!) {
    setInvoicePaid(input: $input)
  }
`;
export const CreatePermissionDocument = gql`
  mutation createPermission($input: CreatePermissionInput!) {
    createPermission(input: $input) {
      ...permission
    }
  }
  ${PermissionFragmentDoc}
`;
export const DeletePermissionDocument = gql`
  mutation deletePermission($input: DeletePermissionInput!) {
    deletePermission(input: $input)
  }
`;
export const GetPermissionDocument = gql`
  query getPermission($input: GetPermissionInput!) {
    getPermission(input: $input) {
      ...permission
    }
  }
  ${PermissionFragmentDoc}
`;
export const GetPermissionsDocument = gql`
  query getPermissions($input: GetPermissionsInput!) {
    getPermissions(input: $input) {
      cursor
      count
      nodes {
        ...permission
      }
    }
  }
  ${PermissionFragmentDoc}
`;
export const UpdatePermissionDocument = gql`
  mutation updatePermission($input: UpdatePermissionInput!) {
    updatePermission(input: $input) {
      ...permission
    }
  }
  ${PermissionFragmentDoc}
`;
export const CreateProjectDocument = gql`
  mutation createProject($input: CreateNutanixProjectInput!) {
    createProject(input: $input)
  }
`;
export const GetLocalProjectsDocument = gql`
  query getLocalProjects($input: GetLocalProjectsInput!) {
    getLocalProjects(input: $input) {
      cursor
      count
      nodes {
        ...localProject
      }
    }
  }
  ${LocalProjectFragmentDoc}
`;
export const MarkLocalProjectDocument = gql`
  mutation markLocalProject($input: MarkLocalProjectInput!) {
    markLocalProject(input: $input) {
      ...localProject
    }
  }
  ${LocalProjectFragmentDoc}
`;
export const RemoveProjectDocument = gql`
  mutation removeProject($input: RemoveNutanixProjectInput!) {
    removeProject(input: $input)
  }
`;
export const UpdateProjectDocument = gql`
  mutation updateProject($input: UpdateNutanixProjectInput!) {
    updateProject(input: $input)
  }
`;
export const ApproveQuotaDocument = gql`
  mutation approveQuota($input: ApproveQuotaInput!) {
    approveQuota(input: $input) {
      ...quota
    }
  }
  ${QuotaFragmentDoc}
`;
export const CreateQuotaDocument = gql`
  mutation createQuota($input: CreateQuotaInput!) {
    createQuota(input: $input) {
      ...quota
    }
  }
  ${QuotaFragmentDoc}
`;
export const GetQuotasDocument = gql`
  query getQuotas($input: GetQuotasInput!) {
    getQuotas(input: $input) {
      count
      cursor
      nodes {
        ...quota
      }
    }
  }
  ${QuotaFragmentDoc}
`;
export const RejectQuotaDocument = gql`
  mutation rejectQuota($input: RejectQuotaInput!) {
    rejectQuota(input: $input) {
      ...quota
    }
  }
  ${QuotaFragmentDoc}
`;
export const SubmitQuotaDocument = gql`
  mutation submitQuota($input: SubmitQuotaInput!) {
    submitQuota(input: $input) {
      ...quota
    }
  }
  ${QuotaFragmentDoc}
`;
export const ApplyResourcePricingDocument = gql`
  mutation applyResourcePricing($input: ApplyResourcePricingInput!) {
    applyResourcePricing(input: $input) {
      ...resourcePricing
    }
  }
  ${ResourcePricingFragmentDoc}
`;
export const CreateResourcePricingDocument = gql`
  mutation createResourcePricing($input: CreateResourcePricingInput!) {
    createResourcePricing(input: $input) {
      ...resourcePricing
    }
  }
  ${ResourcePricingFragmentDoc}
`;
export const GetDailyResourceCostForOrganizationDocument = gql`
  query getDailyResourceCostForOrganization(
    $input: GetDailyResourceCostForOrganizationInput!
  ) {
    getDailyResourceCostForOrganization(input: $input) {
      day
      resourceCosts {
        resourceName
        cost
        quota
        unit
        pricePerUnit
      }
      totalCost
    }
  }
`;
export const GetMonthlyCostForOrganizationDocument = gql`
  query getMonthlyCostForOrganization(
    $input: GetMonthlyCostForOrganizationInput!
  ) {
    getMonthlyCostForOrganization(input: $input) {
      month
      totalCost
    }
  }
`;
export const GetResourcePricingDocument = gql`
  query getResourcePricing($input: GetResourcePricingInput!) {
    getResourcePricing(input: $input) {
      ...resourcePricing
    }
  }
  ${ResourcePricingFragmentDoc}
`;
export const GetResourcePricingsDocument = gql`
  query getResourcePricings($input: GetResourcePricingsInput!) {
    getResourcePricings(input: $input) {
      count
      cursor
      nodes {
        ...resourcePricing
      }
    }
  }
  ${ResourcePricingFragmentDoc}
`;
export const UpdateResourcePricingDocument = gql`
  mutation updateResourcePricing($input: UpdateResourcePricingInput!) {
    updateResourcePricing(input: $input) {
      ...resourcePricing
    }
  }
  ${ResourcePricingFragmentDoc}
`;
export const CreateResourceDocument = gql`
  mutation createResource($input: CreateResourceInput!) {
    createResource(input: $input) {
      ...resource
    }
  }
  ${ResourceFragmentDoc}
`;
export const GetResourceDocument = gql`
  query getResource($input: GetResourceInput!) {
    getResource(input: $input) {
      ...resource
    }
  }
  ${ResourceFragmentDoc}
`;
export const GetResourcesDocument = gql`
  query getResources($input: GetResourcesInput!) {
    getResources(input: $input) {
      count
      cursor
      nodes {
        ...resource
      }
    }
  }
  ${ResourceFragmentDoc}
`;
export const UpdateResourceDocument = gql`
  mutation updateResource($input: UpdateResourceInput!) {
    updateResource(input: $input) {
      ...resource
    }
  }
  ${ResourceFragmentDoc}
`;
export const CreateRoleDocument = gql`
  mutation createRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      ...role
    }
  }
  ${RoleFragmentDoc}
`;
export const DeleteRoleDocument = gql`
  mutation deleteRole($input: DeleteRoleInput!) {
    deleteRole(input: $input)
  }
`;
export const GetRoleDocument = gql`
  query getRole($input: GetRoleInput!) {
    getRole(input: $input) {
      id
      title
      description
      type
      permissions(pagination: { limit: 100, skip: 0 }) {
        count
        cursor
        nodes {
          description
          id
          title
        }
      }
    }
  }
`;
export const GetRolesDocument = gql`
  query getRoles($input: GetRolesInput!) {
    getRoles(input: $input) {
      count
      cursor
      nodes {
        id
        title
        description
        type
        permissions(pagination: { limit: 100, skip: 0 }) {
          count
          cursor
          nodes {
            description
            id
            title
          }
        }
      }
    }
  }
`;
export const UpdateRoleDocument = gql`
  mutation updateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      ...role
    }
  }
  ${RoleFragmentDoc}
`;
export const CreateScopeDocument = gql`
  mutation createScope($input: CreateScopeInput!) {
    createScope(input: $input) {
      ...scope
    }
  }
  ${ScopeFragmentDoc}
`;
export const DeleteScopeDocument = gql`
  mutation deleteScope($input: DeleteScopeInput!) {
    deleteScope(input: $input)
  }
`;
export const GetScopeDocument = gql`
  query getScope($input: GetScopeInput!) {
    getScope(input: $input) {
      ...scope
    }
  }
  ${ScopeFragmentDoc}
`;
export const GetScopesDocument = gql`
  query getScopes($input: GetScopesInput!) {
    getScopes(input: $input) {
      count
      cursor
      nodes {
        ...scope
      }
    }
  }
  ${ScopeFragmentDoc}
`;
export const UpdateScopeDocument = gql`
  mutation updateScope($input: UpdateScopeInput!) {
    updateScope(input: $input) {
      ...scope
    }
  }
  ${ScopeFragmentDoc}
`;
export const CheckUserEmailDocument = gql`
  query checkUserEmail($input: CheckUserEmailInput!) {
    checkUserEmail(input: $input)
  }
`;
export const CheckUserUsernameDocument = gql`
  query checkUserUsername($input: CheckUserUsernameInput!) {
    checkUserUsername(input: $input)
  }
`;
export const CreateUserDocument = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export const DeleteUserDocument = gql`
  mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input)
  }
`;
export const GetMembersDocument = gql`
  query getMembers($input: GetMembersInput!) {
    getMembers(input: $input) {
      count
      cursor
      nodes {
        ...user
      }
    }
  }
  ${UserFragmentDoc}
`;
export const GetUserDocument = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      id
      email
      firstName
      lastName
      userName
      password
      tempPassword
      isEmailConfirmed
      otpSecret
      refreshToken
      createdAt
      updatedAt
      roles {
        role {
          id
          title
          description
          permissions(pagination: { limit: 100, skip: 0 }) {
            nodes {
              id
              title
              description
            }
          }
        }
      }
      company {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const GetUsersDocument = gql`
  query getUsers($input: GetUsersInput!) {
    getUsers(input: $input) {
      count
      cursor
      nodes {
        ...user
      }
    }
  }
  ${UserFragmentDoc}
`;
export const UpdateSelfUserDocument = gql`
  mutation updateSelfUser($input: UpdateUserInput!) {
    updateSelfUser(input: $input) {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export const AssignFloatingIpDocument = gql`
  mutation assignFloatingIp($input: AssignFloatingIpInput!) {
    assignFloatingIp(input: $input)
  }
`;
export const ClearFloatingIpDocument = gql`
  mutation clearFloatingIp($input: ClearFloatingIpInput!) {
    clearFloatingIp(input: $input)
  }
`;
export const GetVmsDocument = gql`
  query getVms($input: GetVmsInput!) {
    getVms(input: $input) {
      allocated
      usage
      entities {
        ...vmEntity
      }
    }
  }
  ${VmEntityFragmentDoc}
`;
export const GetSystemChartDataDocument = gql`
  query getSystemChartData {
    getSystemChartData {
      totalCompanies
      allocated {
        vcpu
        disk
        memory
        publicIps
      }
      usage {
        vcpu
        disk
        memory
      }
    }
  }
`;
export const GetCompanyChartDataDocument = gql`
  query getCompanyChartData($input: GetCompanyChartData!) {
    getCompanyChartData(input: $input) {
      userCount
      projects {
        projectName
        projectUuid
        username
        userUuid
        createdAt
      }
      allocated {
        vcpu
        disk
        memory
        publicIps
      }
      usage {
        vcpu
        disk
        memory
        publicIps
      }
    }
  }
`;
export const GetProjectsChartDataDocument = gql`
  query getProjectsChartData($input: GetProjectsChartData!) {
    getProjectsChartData(input: $input) {
      allocated {
        vcpu
        disk
        memory
        publicIps
      }
      usage {
        vcpu
        disk
        memory
        publicIps
      }
    }
  }
`;
export type Requester<C = {}> = <R, V>(
  doc: DocumentNode,
  vars?: V,
  options?: C,
) => Promise<R> | AsyncIterable<R>;
export function getSdk<C>(requester: Requester<C>) {
  return {
    checkEmailConfirmation(
      variables: CheckEmailConfirmationMutationVariables,
      options?: C,
    ): Promise<CheckEmailConfirmationMutation> {
      return requester<
        CheckEmailConfirmationMutation,
        CheckEmailConfirmationMutationVariables
      >(
        CheckEmailConfirmationDocument,
        variables,
        options,
      ) as Promise<CheckEmailConfirmationMutation>;
    },
    confirmEmail(
      variables: ConfirmEmailMutationVariables,
      options?: C,
    ): Promise<ConfirmEmailMutation> {
      return requester<ConfirmEmailMutation, ConfirmEmailMutationVariables>(
        ConfirmEmailDocument,
        variables,
        options,
      ) as Promise<ConfirmEmailMutation>;
    },
    createMember(
      variables: CreateMemberMutationVariables,
      options?: C,
    ): Promise<CreateMemberMutation> {
      return requester<CreateMemberMutation, CreateMemberMutationVariables>(
        CreateMemberDocument,
        variables,
        options,
      ) as Promise<CreateMemberMutation>;
    },
    enableOtp(
      variables: EnableOtpMutationVariables,
      options?: C,
    ): Promise<EnableOtpMutation> {
      return requester<EnableOtpMutation, EnableOtpMutationVariables>(
        EnableOtpDocument,
        variables,
        options,
      ) as Promise<EnableOtpMutation>;
    },
    forgotPassword(
      variables: ForgotPasswordMutationVariables,
      options?: C,
    ): Promise<ForgotPasswordMutation> {
      return requester<ForgotPasswordMutation, ForgotPasswordMutationVariables>(
        ForgotPasswordDocument,
        variables,
        options,
      ) as Promise<ForgotPasswordMutation>;
    },
    generateOtp(
      variables: GenerateOtpMutationVariables,
      options?: C,
    ): Promise<GenerateOtpMutation> {
      return requester<GenerateOtpMutation, GenerateOtpMutationVariables>(
        GenerateOtpDocument,
        variables,
        options,
      ) as Promise<GenerateOtpMutation>;
    },
    login(
      variables: LoginMutationVariables,
      options?: C,
    ): Promise<LoginMutation> {
      return requester<LoginMutation, LoginMutationVariables>(
        LoginDocument,
        variables,
        options,
      ) as Promise<LoginMutation>;
    },
    logout(
      variables: LogoutMutationVariables,
      options?: C,
    ): Promise<LogoutMutation> {
      return requester<LogoutMutation, LogoutMutationVariables>(
        LogoutDocument,
        variables,
        options,
      ) as Promise<LogoutMutation>;
    },
    refreshTokens(
      variables: RefreshTokensMutationVariables,
      options?: C,
    ): Promise<RefreshTokensMutation> {
      return requester<RefreshTokensMutation, RefreshTokensMutationVariables>(
        RefreshTokensDocument,
        variables,
        options,
      ) as Promise<RefreshTokensMutation>;
    },
    register(
      variables: RegisterMutationVariables,
      options?: C,
    ): Promise<RegisterMutation> {
      return requester<RegisterMutation, RegisterMutationVariables>(
        RegisterDocument,
        variables,
        options,
      ) as Promise<RegisterMutation>;
    },
    setPassword(
      variables: SetPasswordMutationVariables,
      options?: C,
    ): Promise<SetPasswordMutation> {
      return requester<SetPasswordMutation, SetPasswordMutationVariables>(
        SetPasswordDocument,
        variables,
        options,
      ) as Promise<SetPasswordMutation>;
    },
    verifyOtp(
      variables: VerifyOtpMutationVariables,
      options?: C,
    ): Promise<VerifyOtpMutation> {
      return requester<VerifyOtpMutation, VerifyOtpMutationVariables>(
        VerifyOtpDocument,
        variables,
        options,
      ) as Promise<VerifyOtpMutation>;
    },
    createCompany(
      variables: CreateCompanyMutationVariables,
      options?: C,
    ): Promise<CreateCompanyMutation> {
      return requester<CreateCompanyMutation, CreateCompanyMutationVariables>(
        CreateCompanyDocument,
        variables,
        options,
      ) as Promise<CreateCompanyMutation>;
    },
    getCompanies(
      variables: GetCompaniesQueryVariables,
      options?: C,
    ): Promise<GetCompaniesQuery> {
      return requester<GetCompaniesQuery, GetCompaniesQueryVariables>(
        GetCompaniesDocument,
        variables,
        options,
      ) as Promise<GetCompaniesQuery>;
    },
    getCompany(
      variables: GetCompanyQueryVariables,
      options?: C,
    ): Promise<GetCompanyQuery> {
      return requester<GetCompanyQuery, GetCompanyQueryVariables>(
        GetCompanyDocument,
        variables,
        options,
      ) as Promise<GetCompanyQuery>;
    },
    getCompanyBasic(
      variables: GetCompanyBasicQueryVariables,
      options?: C,
    ): Promise<GetCompanyBasicQuery> {
      return requester<GetCompanyBasicQuery, GetCompanyBasicQueryVariables>(
        GetCompanyBasicDocument,
        variables,
        options,
      ) as Promise<GetCompanyBasicQuery>;
    },
    getInvoices(
      variables: GetInvoicesQueryVariables,
      options?: C,
    ): Promise<GetInvoicesQuery> {
      return requester<GetInvoicesQuery, GetInvoicesQueryVariables>(
        GetInvoicesDocument,
        variables,
        options,
      ) as Promise<GetInvoicesQuery>;
    },
    setInvoiceCanceled(
      variables: SetInvoiceCanceledMutationVariables,
      options?: C,
    ): Promise<SetInvoiceCanceledMutation> {
      return requester<
        SetInvoiceCanceledMutation,
        SetInvoiceCanceledMutationVariables
      >(
        SetInvoiceCanceledDocument,
        variables,
        options,
      ) as Promise<SetInvoiceCanceledMutation>;
    },
    setInvoicePaid(
      variables: SetInvoicePaidMutationVariables,
      options?: C,
    ): Promise<SetInvoicePaidMutation> {
      return requester<SetInvoicePaidMutation, SetInvoicePaidMutationVariables>(
        SetInvoicePaidDocument,
        variables,
        options,
      ) as Promise<SetInvoicePaidMutation>;
    },
    createPermission(
      variables: CreatePermissionMutationVariables,
      options?: C,
    ): Promise<CreatePermissionMutation> {
      return requester<
        CreatePermissionMutation,
        CreatePermissionMutationVariables
      >(
        CreatePermissionDocument,
        variables,
        options,
      ) as Promise<CreatePermissionMutation>;
    },
    deletePermission(
      variables: DeletePermissionMutationVariables,
      options?: C,
    ): Promise<DeletePermissionMutation> {
      return requester<
        DeletePermissionMutation,
        DeletePermissionMutationVariables
      >(
        DeletePermissionDocument,
        variables,
        options,
      ) as Promise<DeletePermissionMutation>;
    },
    getPermission(
      variables: GetPermissionQueryVariables,
      options?: C,
    ): Promise<GetPermissionQuery> {
      return requester<GetPermissionQuery, GetPermissionQueryVariables>(
        GetPermissionDocument,
        variables,
        options,
      ) as Promise<GetPermissionQuery>;
    },
    getPermissions(
      variables: GetPermissionsQueryVariables,
      options?: C,
    ): Promise<GetPermissionsQuery> {
      return requester<GetPermissionsQuery, GetPermissionsQueryVariables>(
        GetPermissionsDocument,
        variables,
        options,
      ) as Promise<GetPermissionsQuery>;
    },
    updatePermission(
      variables: UpdatePermissionMutationVariables,
      options?: C,
    ): Promise<UpdatePermissionMutation> {
      return requester<
        UpdatePermissionMutation,
        UpdatePermissionMutationVariables
      >(
        UpdatePermissionDocument,
        variables,
        options,
      ) as Promise<UpdatePermissionMutation>;
    },
    createProject(
      variables: CreateProjectMutationVariables,
      options?: C,
    ): Promise<CreateProjectMutation> {
      return requester<CreateProjectMutation, CreateProjectMutationVariables>(
        CreateProjectDocument,
        variables,
        options,
      ) as Promise<CreateProjectMutation>;
    },
    getLocalProjects(
      variables: GetLocalProjectsQueryVariables,
      options?: C,
    ): Promise<GetLocalProjectsQuery> {
      return requester<GetLocalProjectsQuery, GetLocalProjectsQueryVariables>(
        GetLocalProjectsDocument,
        variables,
        options,
      ) as Promise<GetLocalProjectsQuery>;
    },
    markLocalProject(
      variables: MarkLocalProjectMutationVariables,
      options?: C,
    ): Promise<MarkLocalProjectMutation> {
      return requester<
        MarkLocalProjectMutation,
        MarkLocalProjectMutationVariables
      >(
        MarkLocalProjectDocument,
        variables,
        options,
      ) as Promise<MarkLocalProjectMutation>;
    },
    removeProject(
      variables: RemoveProjectMutationVariables,
      options?: C,
    ): Promise<RemoveProjectMutation> {
      return requester<RemoveProjectMutation, RemoveProjectMutationVariables>(
        RemoveProjectDocument,
        variables,
        options,
      ) as Promise<RemoveProjectMutation>;
    },
    updateProject(
      variables: UpdateProjectMutationVariables,
      options?: C,
    ): Promise<UpdateProjectMutation> {
      return requester<UpdateProjectMutation, UpdateProjectMutationVariables>(
        UpdateProjectDocument,
        variables,
        options,
      ) as Promise<UpdateProjectMutation>;
    },
    approveQuota(
      variables: ApproveQuotaMutationVariables,
      options?: C,
    ): Promise<ApproveQuotaMutation> {
      return requester<ApproveQuotaMutation, ApproveQuotaMutationVariables>(
        ApproveQuotaDocument,
        variables,
        options,
      ) as Promise<ApproveQuotaMutation>;
    },
    createQuota(
      variables: CreateQuotaMutationVariables,
      options?: C,
    ): Promise<CreateQuotaMutation> {
      return requester<CreateQuotaMutation, CreateQuotaMutationVariables>(
        CreateQuotaDocument,
        variables,
        options,
      ) as Promise<CreateQuotaMutation>;
    },
    getQuotas(
      variables: GetQuotasQueryVariables,
      options?: C,
    ): Promise<GetQuotasQuery> {
      return requester<GetQuotasQuery, GetQuotasQueryVariables>(
        GetQuotasDocument,
        variables,
        options,
      ) as Promise<GetQuotasQuery>;
    },
    rejectQuota(
      variables: RejectQuotaMutationVariables,
      options?: C,
    ): Promise<RejectQuotaMutation> {
      return requester<RejectQuotaMutation, RejectQuotaMutationVariables>(
        RejectQuotaDocument,
        variables,
        options,
      ) as Promise<RejectQuotaMutation>;
    },
    submitQuota(
      variables: SubmitQuotaMutationVariables,
      options?: C,
    ): Promise<SubmitQuotaMutation> {
      return requester<SubmitQuotaMutation, SubmitQuotaMutationVariables>(
        SubmitQuotaDocument,
        variables,
        options,
      ) as Promise<SubmitQuotaMutation>;
    },
    applyResourcePricing(
      variables: ApplyResourcePricingMutationVariables,
      options?: C,
    ): Promise<ApplyResourcePricingMutation> {
      return requester<
        ApplyResourcePricingMutation,
        ApplyResourcePricingMutationVariables
      >(
        ApplyResourcePricingDocument,
        variables,
        options,
      ) as Promise<ApplyResourcePricingMutation>;
    },
    createResourcePricing(
      variables: CreateResourcePricingMutationVariables,
      options?: C,
    ): Promise<CreateResourcePricingMutation> {
      return requester<
        CreateResourcePricingMutation,
        CreateResourcePricingMutationVariables
      >(
        CreateResourcePricingDocument,
        variables,
        options,
      ) as Promise<CreateResourcePricingMutation>;
    },
    getDailyResourceCostForOrganization(
      variables: GetDailyResourceCostForOrganizationQueryVariables,
      options?: C,
    ): Promise<GetDailyResourceCostForOrganizationQuery> {
      return requester<
        GetDailyResourceCostForOrganizationQuery,
        GetDailyResourceCostForOrganizationQueryVariables
      >(
        GetDailyResourceCostForOrganizationDocument,
        variables,
        options,
      ) as Promise<GetDailyResourceCostForOrganizationQuery>;
    },
    getMonthlyCostForOrganization(
      variables: GetMonthlyCostForOrganizationQueryVariables,
      options?: C,
    ): Promise<GetMonthlyCostForOrganizationQuery> {
      return requester<
        GetMonthlyCostForOrganizationQuery,
        GetMonthlyCostForOrganizationQueryVariables
      >(
        GetMonthlyCostForOrganizationDocument,
        variables,
        options,
      ) as Promise<GetMonthlyCostForOrganizationQuery>;
    },
    getResourcePricing(
      variables: GetResourcePricingQueryVariables,
      options?: C,
    ): Promise<GetResourcePricingQuery> {
      return requester<
        GetResourcePricingQuery,
        GetResourcePricingQueryVariables
      >(
        GetResourcePricingDocument,
        variables,
        options,
      ) as Promise<GetResourcePricingQuery>;
    },
    getResourcePricings(
      variables: GetResourcePricingsQueryVariables,
      options?: C,
    ): Promise<GetResourcePricingsQuery> {
      return requester<
        GetResourcePricingsQuery,
        GetResourcePricingsQueryVariables
      >(
        GetResourcePricingsDocument,
        variables,
        options,
      ) as Promise<GetResourcePricingsQuery>;
    },
    updateResourcePricing(
      variables: UpdateResourcePricingMutationVariables,
      options?: C,
    ): Promise<UpdateResourcePricingMutation> {
      return requester<
        UpdateResourcePricingMutation,
        UpdateResourcePricingMutationVariables
      >(
        UpdateResourcePricingDocument,
        variables,
        options,
      ) as Promise<UpdateResourcePricingMutation>;
    },
    createResource(
      variables: CreateResourceMutationVariables,
      options?: C,
    ): Promise<CreateResourceMutation> {
      return requester<CreateResourceMutation, CreateResourceMutationVariables>(
        CreateResourceDocument,
        variables,
        options,
      ) as Promise<CreateResourceMutation>;
    },
    getResource(
      variables: GetResourceQueryVariables,
      options?: C,
    ): Promise<GetResourceQuery> {
      return requester<GetResourceQuery, GetResourceQueryVariables>(
        GetResourceDocument,
        variables,
        options,
      ) as Promise<GetResourceQuery>;
    },
    getResources(
      variables: GetResourcesQueryVariables,
      options?: C,
    ): Promise<GetResourcesQuery> {
      return requester<GetResourcesQuery, GetResourcesQueryVariables>(
        GetResourcesDocument,
        variables,
        options,
      ) as Promise<GetResourcesQuery>;
    },
    updateResource(
      variables: UpdateResourceMutationVariables,
      options?: C,
    ): Promise<UpdateResourceMutation> {
      return requester<UpdateResourceMutation, UpdateResourceMutationVariables>(
        UpdateResourceDocument,
        variables,
        options,
      ) as Promise<UpdateResourceMutation>;
    },
    createRole(
      variables: CreateRoleMutationVariables,
      options?: C,
    ): Promise<CreateRoleMutation> {
      return requester<CreateRoleMutation, CreateRoleMutationVariables>(
        CreateRoleDocument,
        variables,
        options,
      ) as Promise<CreateRoleMutation>;
    },
    deleteRole(
      variables: DeleteRoleMutationVariables,
      options?: C,
    ): Promise<DeleteRoleMutation> {
      return requester<DeleteRoleMutation, DeleteRoleMutationVariables>(
        DeleteRoleDocument,
        variables,
        options,
      ) as Promise<DeleteRoleMutation>;
    },
    getRole(
      variables: GetRoleQueryVariables,
      options?: C,
    ): Promise<GetRoleQuery> {
      return requester<GetRoleQuery, GetRoleQueryVariables>(
        GetRoleDocument,
        variables,
        options,
      ) as Promise<GetRoleQuery>;
    },
    getRoles(
      variables: GetRolesQueryVariables,
      options?: C,
    ): Promise<GetRolesQuery> {
      return requester<GetRolesQuery, GetRolesQueryVariables>(
        GetRolesDocument,
        variables,
        options,
      ) as Promise<GetRolesQuery>;
    },
    updateRole(
      variables: UpdateRoleMutationVariables,
      options?: C,
    ): Promise<UpdateRoleMutation> {
      return requester<UpdateRoleMutation, UpdateRoleMutationVariables>(
        UpdateRoleDocument,
        variables,
        options,
      ) as Promise<UpdateRoleMutation>;
    },
    createScope(
      variables: CreateScopeMutationVariables,
      options?: C,
    ): Promise<CreateScopeMutation> {
      return requester<CreateScopeMutation, CreateScopeMutationVariables>(
        CreateScopeDocument,
        variables,
        options,
      ) as Promise<CreateScopeMutation>;
    },
    deleteScope(
      variables: DeleteScopeMutationVariables,
      options?: C,
    ): Promise<DeleteScopeMutation> {
      return requester<DeleteScopeMutation, DeleteScopeMutationVariables>(
        DeleteScopeDocument,
        variables,
        options,
      ) as Promise<DeleteScopeMutation>;
    },
    getScope(
      variables: GetScopeQueryVariables,
      options?: C,
    ): Promise<GetScopeQuery> {
      return requester<GetScopeQuery, GetScopeQueryVariables>(
        GetScopeDocument,
        variables,
        options,
      ) as Promise<GetScopeQuery>;
    },
    getScopes(
      variables: GetScopesQueryVariables,
      options?: C,
    ): Promise<GetScopesQuery> {
      return requester<GetScopesQuery, GetScopesQueryVariables>(
        GetScopesDocument,
        variables,
        options,
      ) as Promise<GetScopesQuery>;
    },
    updateScope(
      variables: UpdateScopeMutationVariables,
      options?: C,
    ): Promise<UpdateScopeMutation> {
      return requester<UpdateScopeMutation, UpdateScopeMutationVariables>(
        UpdateScopeDocument,
        variables,
        options,
      ) as Promise<UpdateScopeMutation>;
    },
    checkUserEmail(
      variables: CheckUserEmailQueryVariables,
      options?: C,
    ): Promise<CheckUserEmailQuery> {
      return requester<CheckUserEmailQuery, CheckUserEmailQueryVariables>(
        CheckUserEmailDocument,
        variables,
        options,
      ) as Promise<CheckUserEmailQuery>;
    },
    checkUserUsername(
      variables: CheckUserUsernameQueryVariables,
      options?: C,
    ): Promise<CheckUserUsernameQuery> {
      return requester<CheckUserUsernameQuery, CheckUserUsernameQueryVariables>(
        CheckUserUsernameDocument,
        variables,
        options,
      ) as Promise<CheckUserUsernameQuery>;
    },
    createUser(
      variables: CreateUserMutationVariables,
      options?: C,
    ): Promise<CreateUserMutation> {
      return requester<CreateUserMutation, CreateUserMutationVariables>(
        CreateUserDocument,
        variables,
        options,
      ) as Promise<CreateUserMutation>;
    },
    deleteUser(
      variables: DeleteUserMutationVariables,
      options?: C,
    ): Promise<DeleteUserMutation> {
      return requester<DeleteUserMutation, DeleteUserMutationVariables>(
        DeleteUserDocument,
        variables,
        options,
      ) as Promise<DeleteUserMutation>;
    },
    getMembers(
      variables: GetMembersQueryVariables,
      options?: C,
    ): Promise<GetMembersQuery> {
      return requester<GetMembersQuery, GetMembersQueryVariables>(
        GetMembersDocument,
        variables,
        options,
      ) as Promise<GetMembersQuery>;
    },
    getUser(
      variables: GetUserQueryVariables,
      options?: C,
    ): Promise<GetUserQuery> {
      return requester<GetUserQuery, GetUserQueryVariables>(
        GetUserDocument,
        variables,
        options,
      ) as Promise<GetUserQuery>;
    },
    getUsers(
      variables: GetUsersQueryVariables,
      options?: C,
    ): Promise<GetUsersQuery> {
      return requester<GetUsersQuery, GetUsersQueryVariables>(
        GetUsersDocument,
        variables,
        options,
      ) as Promise<GetUsersQuery>;
    },
    updateSelfUser(
      variables: UpdateSelfUserMutationVariables,
      options?: C,
    ): Promise<UpdateSelfUserMutation> {
      return requester<UpdateSelfUserMutation, UpdateSelfUserMutationVariables>(
        UpdateSelfUserDocument,
        variables,
        options,
      ) as Promise<UpdateSelfUserMutation>;
    },
    updateUser(
      variables: UpdateUserMutationVariables,
      options?: C,
    ): Promise<UpdateUserMutation> {
      return requester<UpdateUserMutation, UpdateUserMutationVariables>(
        UpdateUserDocument,
        variables,
        options,
      ) as Promise<UpdateUserMutation>;
    },
    assignFloatingIp(
      variables: AssignFloatingIpMutationVariables,
      options?: C,
    ): Promise<AssignFloatingIpMutation> {
      return requester<
        AssignFloatingIpMutation,
        AssignFloatingIpMutationVariables
      >(
        AssignFloatingIpDocument,
        variables,
        options,
      ) as Promise<AssignFloatingIpMutation>;
    },
    clearFloatingIp(
      variables: ClearFloatingIpMutationVariables,
      options?: C,
    ): Promise<ClearFloatingIpMutation> {
      return requester<
        ClearFloatingIpMutation,
        ClearFloatingIpMutationVariables
      >(
        ClearFloatingIpDocument,
        variables,
        options,
      ) as Promise<ClearFloatingIpMutation>;
    },
    getVms(variables: GetVmsQueryVariables, options?: C): Promise<GetVmsQuery> {
      return requester<GetVmsQuery, GetVmsQueryVariables>(
        GetVmsDocument,
        variables,
        options,
      ) as Promise<GetVmsQuery>;
    },
    getSystemChartData(
      variables?: GetSystemChartDataQueryVariables,
      options?: C,
    ): Promise<GetSystemChartDataQuery> {
      return requester<
        GetSystemChartDataQuery,
        GetSystemChartDataQueryVariables
      >(
        GetSystemChartDataDocument,
        variables,
        options,
      ) as Promise<GetSystemChartDataQuery>;
    },
    getCompanyChartData(
      variables: GetCompanyChartDataQueryVariables,
      options?: C,
    ): Promise<GetCompanyChartDataQuery> {
      return requester<
        GetCompanyChartDataQuery,
        GetCompanyChartDataQueryVariables
      >(
        GetCompanyChartDataDocument,
        variables,
        options,
      ) as Promise<GetCompanyChartDataQuery>;
    },
    getProjectsChartData(
      variables: GetProjectsChartDataQueryVariables,
      options?: C,
    ): Promise<GetProjectsChartDataQuery> {
      return requester<
        GetProjectsChartDataQuery,
        GetProjectsChartDataQueryVariables
      >(
        GetProjectsChartDataDocument,
        variables,
        options,
      ) as Promise<GetProjectsChartDataQuery>;
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
