import React from 'react';

import { Flex } from '@chakra-ui/react';
import { Notification } from '../Notification';
import { Profile } from '../Profile';
import IconLogo from 'shared/assets/svg/common/icLogo.svg?react';
import IconLogoLabel from 'shared/assets/svg/common/icLogoLabel.svg?react';
import { Link } from 'react-router-dom';
import { useUnit } from 'effector-react';
import { profileModel } from 'entities/Profile/model';
import { endPoints } from 'processes/Router/endPoints';

export const Navbar: React.FC = () => {
  const isSUSER = useUnit(profileModel.selfUser.stores.$isSUSER);
  return (
    <Flex
      alignItems='center'
      justifyContent='space-between'
      minHeight='60px'
      bg='surface.gradient'
      paddingInline={4}>
      <Link to={isSUSER ? endPoints.USERS : endPoints.MY_COMPANY}>
        <Flex alignItems='center' columnGap={2}>
          <IconLogo />
          <IconLogoLabel />
        </Flex>
      </Link>
      <Flex columnGap={4}>
        <Notification />
        <Profile />
      </Flex>
    </Flex>
  );
};
