import { createEvent, sample } from 'effector';
import { notificationsModel } from 'entities/Notification/model';

const initCache = createEvent();
const resetCache = createEvent();

sample({
  clock: initCache,
  target: [
    notificationsModel.notifications.events.initNotificationsCache,
    notificationsModel.notifications.events.initAllReadNotificationsCache,
  ],
});

sample({
  clock: resetCache,
  target: [
    notificationsModel.notifications.events.resetNotificationCache,
    notificationsModel.notifications.events.resetAllReadNotificationsCache,
  ],
});

export const cache = {
  events: {
    initCache,
    resetCache,
  },
};
