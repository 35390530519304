import { createEffect, createEvent, createStore, sample } from 'effector';
import { CheckEmailConfirmationMutationVariables } from 'shared/api/apollo/__generated__';
import { requestsClient } from 'shared/api/client';

const resetEmailConfirmation = createEvent();
const $isEmailConfirmationValid = createStore<boolean>(false).reset(
  resetEmailConfirmation,
);

const checkEmailConfirmation =
  createEvent<CheckEmailConfirmationMutationVariables>();
const checkEmailConfirmationFx = createEffect(
  requestsClient.checkEmailConfirmation,
);

sample({
  clock: checkEmailConfirmation,
  target: checkEmailConfirmationFx,
});

sample({
  clock: checkEmailConfirmationFx.doneData,
  fn: (result) => result.checkEmailConfirmation,
  target: $isEmailConfirmationValid,
});

export const init = {
  stores: {
    $isEmailConfirmationValid,
    $pending: checkEmailConfirmationFx.pending,
  },
  events: {
    resetEmailConfirmation,
    checkEmailConfirmation,
  },
};
