import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/ui/atoms/Button';
import { confirmEmailModel } from './model';
import { useUnit } from 'effector-react';
import { match } from 'ts-pattern';
import { EEffectState } from 'shared/lib/stateEffect/types';
import Spinner from 'shared/ui/atoms/Spinner';
import { Card } from 'shared/ui/atoms/Card';
import { Text } from 'shared/ui/atoms/Text';
import { Link } from '@chakra-ui/react';
import { useEffect } from 'react';
import { SpinnerBlock } from 'shared/ui/molecules/SpinnerBlock';

export const ConfirmEmail = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const [state, isEmailConfirmationValid, pending] = useUnit([
    confirmEmailModel.confirm.confirmEmailState.$state,
    confirmEmailModel.init.stores.$isEmailConfirmationValid,
    confirmEmailModel.init.stores.$pending,
  ]);
  const token = params.get('token');

  useEffect(() => {
    if (token)
      confirmEmailModel.init.events.checkEmailConfirmation({
        input: {
          token,
        },
      });
  }, [token]);

  const onConfirm = async () => {
    const token = params.get('token');
    if (token) confirmEmailModel.confirm.events.confirmEmail(token);
  };

  if (pending) return <SpinnerBlock size={60} />;

  return (
    <Card
      props={{
        width: '600px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Text props={{ marginBlock: 4 }} size='2xl'>
        {t(
          isEmailConfirmationValid
            ? 'confirmEmail.titleValid'
            : 'confirmEmail.title',
        )}
      </Text>

      {isEmailConfirmationValid ? (
        <>
          <Link href='/' textAlign='center' color='blue.500'>
            <p>{t('confirmEmail.linkValid')}</p>
          </Link>
        </>
      ) : (
        <>
          <Text props={{ marginBottom: 6, textAlign: 'center' }} size='lg'>
            {t('confirmEmail.prompt')}
          </Text>
          {match(state)
            .with(EEffectState.IDLE, () => (
              <Button props={{ width: 40 }} onClick={onConfirm}>
                {t('confirmEmail.button')}
              </Button>
            ))
            .with(EEffectState.PENDING, () => <Spinner />)
            .with(EEffectState.SUCCESS, () => (
              <Link href='/' textAlign='center' color='blue.500'>
                <p>{t('confirmEmail.success')}</p>
              </Link>
            ))
            .otherwise(() => (
              <Button props={{ width: 40 }} onClick={onConfirm}>
                {t('confirmEmail.button')}
              </Button>
            ))}
        </>
      )}
    </Card>
  );
};
