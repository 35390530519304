import { createEffect, createEvent, createStore, sample } from 'effector';
import { createForm } from 'effector-forms';

import { ForgotPasswordMutationVariables } from 'shared/api/apollo/__generated__';
import { requestsClient } from 'shared/api/client';
import { rules } from 'shared/lib/rules';
import { toastEffect } from 'shared/lib/toastEffect';

const forgotPasswordEvent = createEvent<ForgotPasswordMutationVariables>();
const forgotPasswordFx = toastEffect(
  createEffect(requestsClient.forgotPassword),
  {
    errorMessage: 'forgotPassword.error',
  },
);

sample({
  clock: forgotPasswordEvent,
  target: forgotPasswordFx,
});

const resetIsEmailLinkSend = createEvent();
const $isEmailLinkSend =
  createStore<boolean>(false).reset(resetIsEmailLinkSend);

const form = createForm({
  fields: {
    email: {
      init: '' as string,
      rules: [rules.requiredString(), rules.emailPattern()],
    },
  },
  validateOn: ['submit'],
});

sample({
  clock: form.formValidated,
  fn: ({ email }) => ({ input: { email } }),
  target: forgotPasswordEvent,
});

sample({
  clock: forgotPasswordFx.doneData,
  fn: () => true,
  target: $isEmailLinkSend,
});

export const forgotPassword = {
  form,
  stores: {
    $pending: forgotPasswordFx.pending,
    $isEmailLinkSend,
  },
  events: {
    forgotPasswordEvent,
    resetIsEmailLinkSend,
  },
};
