import { createStore, sample } from 'effector';
import {
  CACHE_KEY_ARE_ALL_READ_NOTIFICATIONS,
  CACHE_KEY_NOTIFICATIONS,
} from 'shared/config/cache';
import { cacheFactory } from 'shared/lib/cacheFactory';
import { INotification } from '../types';
import { floatingIp } from './floatingIp';
import { projects } from './projects';
import { quotas } from './quotas';

const $areAllNotificationsRead = createStore<boolean>(true);

const $notifications = createStore<INotification[]>([]);
const $sortedNotifications = createStore<INotification[]>([]);

const {
  initCache: initNotificationsCache,
  resetCache: resetNotificationCache,
} = cacheFactory({
  $store: $notifications,
  cacheKey: CACHE_KEY_NOTIFICATIONS,
  isPureStore: true,
});

const {
  initCache: initAllReadNotificationsCache,
  resetCache: resetAllReadNotificationsCache,
} = cacheFactory({
  $store: $areAllNotificationsRead,
  cacheKey: CACHE_KEY_ARE_ALL_READ_NOTIFICATIONS,
  isPureStore: true,
});

const $notificationsCount = createStore<number>(0).reset(
  resetNotificationCache,
);

sample({
  clock: projects.events.addProjectNotification,
  source: $notifications,
  fn: (notifications, notification) => [...notifications, notification],
  target: $notifications,
});

sample({
  clock: quotas.events.addQuotaNotification,
  source: $notifications,
  fn: (notifications, notification) => [...notifications, notification],
  target: $notifications,
});

sample({
  clock: floatingIp.events.addFloatingIpNotification,
  source: $notifications,
  fn: (notifications, notification) => [...notifications, notification],
  target: $notifications,
});

sample({
  clock: [
    projects.events.addProjectNotification,
    quotas.events.addQuotaNotification,
    floatingIp.events.addFloatingIpNotification,
  ],
  fn: () => false,
  target: $areAllNotificationsRead,
});

sample({
  clock: $notifications,
  fn: (notifications) => notifications?.length ?? 0,
  target: $notificationsCount,
});

sample({
  clock: $notifications,
  fn: (notifications) =>
    notifications.sort((a, b) => b.timestamp - a.timestamp),
  target: $sortedNotifications,
});

export const notifications = {
  stores: {
    $notifications,
    $sortedNotifications,
    $notificationsCount,
    $areAllNotificationsRead,
  },
  events: {
    initNotificationsCache,
    resetNotificationCache,
    initAllReadNotificationsCache,
    resetAllReadNotificationsCache,
  },
};
