export const PASSWORD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!?@#$%^&*_\-+()[\]{}><|/\\"'.,:;])[A-Za-z\d~!?@#$%^&*_\-+()[\]{}><|/\\"'.,:;]{8,128}$/m;
export const GLOBAL_SPACE = /\s/g;
export const GLOBAL_COMMA = /,/g;
export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const HOST = /\b(?:\d{1,3}\.){3}\d{1,3}\b/;
export const FIRSTNAME_LASTNAME = /^[A-Za-z][A-Za-z\s\-_`‘’']*$/;
export const LETTERS_NUMBERS = /^[A-Za-z0-9]+$/;
export const FIRST_LETTER_MUST_BE_LATIN = /^[A-Za-z].*$/;

export const IP_ADDRESS_WITH_CIDR =
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|1[0-9]|2[0-9]|3[0-2]))$/;
export const IP_ADDRESS_WITHOUT_CIDR =
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
